import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TokenService } from '../../token/token.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NexfordApiService {

  private nexfordUrl = environment.edupayUrl;
  private nexford = 'Nextford';

  constructor(
    private http: HttpClient,
    private token: TokenService
  ) { }

  private refreshNeeded = new Subject<void>();

  get refreshedTransaction() {
    return this.refreshNeeded;
  }

  get refreshedRate() {
    return this.refreshNeeded;
  }

  get refreshedFormA() {
    return this.refreshNeeded;
  }

  get refreshedExchangeRate() {
    return this.refreshNeeded;
  }

  get refreshedDiscount() {
    return this.refreshNeeded;
  }

  get refreshedCourse() {
    return this.refreshNeeded;
  }

  get refreshedIncomeAccountNumber() {
    return this.refreshNeeded;
  }

  get refreshedInsuranceAccountNumber() {
    return this.refreshNeeded;
  }

  get refreshedSettlementAccountNumber() {
    return this.refreshNeeded;
  }

  get refreshedNexfordAccountNumber() {
    return this.refreshNeeded;
  }

  get refreshedStudents() {
    return this.refreshNeeded;
  }

  getNexfordProspect() {
    const userId = new HttpParams().set('edubankUserID', this.token.getid());
    return this.http.get(`${this.nexfordUrl}/${this.nexford}/Prospect/GetByEdubankUserID`, {params: userId});
  }

  getProxyEdubancUserId() {
    const userId = new HttpParams().set('edubankUserID', this.token.getid());
    return this.http.get(`${this.nexfordUrl}/${this.nexford}/Proxy/GetByEdubankUserID`, {params: userId});
  }

  getAdminDashboardCards() {
    return this.http.get(`${this.nexfordUrl}/${this.nexford}/GetAdminDashboardCards`);
  }

  getActivityLog() {
    return this.http.get(`${this.nexfordUrl}/${this.nexford}/GetActivityLogs`);
  }

  getPayStackAccountNumber() {
    return this.http.get(`${this.nexfordUrl}/${this.nexford}/Settings/GetPaystackAccountNumber`);
  }

  getNexfordAccountNumber() {
    return this.http.get(`${this.nexfordUrl}/${this.nexford}/Settings/GetNexfordAccountNumber`);
  }

  getNexfordDetails() {
    return this.http.get(`${this.nexfordUrl}/${this.nexford}/Platform/Get`);
  }

  getNexfordSchoolDetails() {
    return this.http.get(`${this.nexfordUrl}/${this.nexford}/School/GetNextfordDetails`);
  }

  getUnapprovedIncomeAccountNumber() {
    return this.http.get(`${this.nexfordUrl}/${this.nexford}/Settings/GetUnapprovedIncomeAccountNumberUpdate`);
  }

  updateIncomeAccountNumber(data) {
    return this.http.put(`${this.nexfordUrl}/${this.nexford}/Settings/UpdateIncomeNumberRequest`, data)
    .pipe(
      tap(() => {
        this.refreshedIncomeAccountNumber.next();
      })
    );
  }

  approveOrDeclineIncomeAccountNumber(data) {
    return this.http.put(`${this.nexfordUrl}/${this.nexford}/Settings/ApproveOrDeclineIncomeAccount`, data)
    .pipe(
      tap(() => {
        this.refreshedIncomeAccountNumber.next();
      })
    );
  }

  getUnapprovedInsuranceAccountNumber() {
    return this.http.get(`${this.nexfordUrl}/${this.nexford}/Settings/GetUnapprovedInsuranceAccountNumberUpdate`);
  }

  updateInsuranceAccountNumber(data) {
    return this.http.put(`${this.nexfordUrl}/${this.nexford}/Settings/UpdateInsuranceNumberRequest`, data)
    .pipe(
      tap(() => {
        this.refreshedInsuranceAccountNumber.next();
      })
    );
  }

  approveOrDeclineInsuranceAccountNumber(data) {
    return this.http.put(`${this.nexfordUrl}/${this.nexford}/Settings/ApproveInsuranceAccountNumber`, data)
    .pipe(
      tap(() => {
        this.refreshedInsuranceAccountNumber.next();
      })
    );
  }

  getUnapprovedSettlementAccountNumber() {
    return this.http.get(`${this.nexfordUrl}/${this.nexford}/Settings/GetUnapprovedSettlementAccountNumberUpdate`);
  }

  updateSettlementAccountNumber(data) {
    return this.http.put(`${this.nexfordUrl}/${this.nexford}/Settings/UpdateSettlementNumberRequest`, data)
    .pipe(
      tap(() => {
        this.refreshedSettlementAccountNumber.next();
      })
    );
  }

  approveOrDeclineSettlementAccountNumber(data) {
    return this.http.put(`${this.nexfordUrl}/${this.nexford}/Settings/ApproveOrDeclineSettlementAccount`, data)
    .pipe(
      tap(() => {
        this.refreshedSettlementAccountNumber.next();
      })
    );
  }

  updateNexfordAccountNumber(data) {
    return this.http.put(`${this.nexfordUrl}/${this.nexford}/Settings/UpdateNexfordSchoolAccountNumberRequest`, data)
    .pipe(
      tap(() => {
        this.refreshedNexfordAccountNumber.next();
      })
    );
  }

  getUnapprovedNexfordAccountNumber() {
    return this.http.get(`${this.nexfordUrl}/${this.nexford}/Settings/GetUnapprovedNexfordSchoolAccountNumberUpdate`);
  }

  approveOrDeclineNexfordAccountNumber(data) {
    return this.http.put(`${this.nexfordUrl}/${this.nexford}/Settings/ApproveOrDeclineNexfordSchoolAccount`, data)
    .pipe(
      tap(() => {
        this.refreshedNexfordAccountNumber.next();
      })
    );
  }

  getAllNexfordLoans() {
    return this.http.get(`${this.nexfordUrl}/${this.nexford}/GetAllLoanHistory`);
  }

  loanDisbursement(data) {
    return this.http.post(`${this.nexfordUrl}/${this.nexford}/Loan/UnlockAndTransferFunds`, data)
    .pipe(
      tap(() => {
        this.refreshedTransaction.next();
      })
    );
  }

  loanDetailsBreakdown(data) {
    const transId = new HttpParams().set('transactionID', data);
    return this.http.get(`${this.nexfordUrl}/${this.nexford}/Dashboard/GetLoanBreakdownByTransactionID`, {params: transId});
  }

  nextLoanRepaymentInformation(data) {
    return this.http.post(`${this.nexfordUrl}/${this.nexford}/Dashboard/NextRepaymentInformation`, data);
  }

  loanInformation(data) {
    const transId = new HttpParams().set('transactionID', data);
    return this.http.get(`${this.nexfordUrl}/${this.nexford}/Dashboard/GetLoanInformation`, {params: transId});
  }

  beneficiaries(data) {
    const transId = new HttpParams().set('transactionID', data);
    return this.http.get(`${this.nexfordUrl}/${this.nexford}/Dashboard/GetBeneficiaries`, {params: transId});
  }

  loanActivities(data) {
    const transId = new HttpParams().set('transactionID', data);
    return this.http.get(`${this.nexfordUrl}/${this.nexford}/Dashboard/LoanActivities`, {params: transId});
  }

  updateRate(data) {
    return this.http.put(`${this.nexfordUrl}/${this.nexford}/Platform/UpdateDetails`, data)
    .pipe(
      tap(() => {
        this.refreshedRate.next();
      })
    );
  }

  getAdmittedStudent() {
    return this.http.get(`${this.nexfordUrl}/${this.nexford}/Prospect/GetAdmittedStudents`);
  }

  uploadProspect(data) {
    return this.http.put(`${this.nexfordUrl}/${this.nexford}/Prospect/UploadProspects`, data)
    .pipe(
      tap(() => {
        this.refreshedStudents.next();
      })
    );
  }

  getExchangeRate() {
    return this.http.get(`${this.nexfordUrl}/${this.nexford}/Settings/GetCurrentExchangeRate`);
  }

  getFormA() {
    return this.http.get(`${this.nexfordUrl}/${this.nexford}/Courses/GetDollarProcessingFee`);
  }

  updateFormA(data) {
    return this.http.put(`${this.nexfordUrl}/${this.nexford}/Courses/UpdateDollarProcessingFee`, data)
    .pipe(
      tap(() => {
        this.refreshedFormA.next();
      })
    );
  }

  updateExchangeRate(data) {
    return this.http.put(`${this.nexfordUrl}/${this.nexford}/Settings/UpdateExchangeRate`, data)
    .pipe(
      tap(() => {
        this.refreshedExchangeRate.next();
      })
    );
  }

  updateDiscount(data) {
    return this.http.put(`${this.nexfordUrl}/${this.nexford}/School/UpdateSessionDiscount`, data)
    .pipe(
      tap(() => {
        this.refreshedDiscount.next();
      })
    );
  }

  updateCourse(data) {
    return this.http.put(`${this.nexfordUrl}/${this.nexford}/Courses/UpdateCourse`, data)
    .pipe(
      tap(() => {
        this.refreshedCourse.next();
      })
    );
  }

  getAllNexfordCourses() {
    return this.http.get(`${this.nexfordUrl}/${this.nexford}/Courses/GetAll`);
  }

  getNexfordSingleMonthFee() {
    return this.http.get(`${this.nexfordUrl}/${this.nexford}/Courses/GetSingleMonthFee`);
  }

  getLoanStatusFromT24(data) {
    const transId = new HttpParams().set('transactionID', data);
    return this.http.get(`${this.nexfordUrl}/${this.nexford}/GetLoanStatusFromT24`, {params: transId});
  }

  initiateDebitNexford(data) {
    return this.http.post(`${this.nexfordUrl}/${this.nexford}/InitiateDebit`, data);
  }

  getAllDirectPayment() {
    return this.http.get(`${this.nexfordUrl}/${this.nexford}/DirectPayment/FetchAllForTable`);
  }


}
