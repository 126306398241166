import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { TokenService } from '../token/token.service';
import { environment } from 'src/environments/environment';
import { Subject, BehaviorSubject, throwError, Observable, of } from 'rxjs';
import { tap, map, catchError, filter, switchMap, shareReplay } from 'rxjs/operators';
import { ThrowStmt } from '@angular/compiler';


interface IDashboardCard {
  activeUserCount: number;
  inActiveUserCount: number;
  registeredUserCount: number;
  totaldonationAmount: number;
  totaldonationCount: number;
}

@Injectable({
  providedIn: 'root'
})
export class AdminApiService {

  private baseUrl = environment.baseUrl;

  private helpCenter = 'HelpCenter';

  private notification = 'Notification';

  private account = 'Account';

  private role = 'Roles';

  private dashboard = 'Dashboard';

  private tti = 'OneMillionTeacher';

  private sterlingCustm = 'SterlingCustomer';

  private customer_profile = 'CustomersProfile';

  private accountGenByFilter = 'SterlingCustomer/AccountGeneratedByFilter';

  private dashBoardCardInfo: IDashboardCard;


  // PUSH BASED APPROACH FOR CUSTOMER PROFILE

  private customerReqSubject = new BehaviorSubject(null);
  readonly customerProfileReq$ = this.customerReqSubject.asObservable();


  loadCustomerProfileReq() {
    this.getAllCustomerProfileRequest().subscribe(requests => {
      // console.log('[Subscribing inside the service]:', requests);
      this.customerReqSubject.next(requests);
    });
  }

  // requests$ = this.getAllCustomerProfileRequest();


  private getAllCustomerProfileRequest(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}/${this.customer_profile}`);
  }

   getDeclinedRequest(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}/${this.customer_profile}/Decline`);
  }

   getApprovedRequest(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}/${this.customer_profile}/Approve`);
  }



  // CustomersProfile/{adminUserId}
  approveOrDecline(adminId: string, customerInfo: Object) {
    return this.http.put(`${this.baseUrl}/${this.customer_profile}/adminId`, customerInfo);
  }
















  // all refresh functions

  private refreshNeeded = new Subject<void>();

  private dashboardCards = new Subject();
  activeUserSourceChanges$ = this.dashboardCards.asObservable();

  // style 2 for behaviorSubject
  private dashboardCardSource = new BehaviorSubject(null);
  dashboardCardValueChanges$ = this.dashboardCardSource.asObservable();



  // dashBoardCardsValueChanges$ = this.dashboardCards.asObservable();

  private serviceBreakDown = new BehaviorSubject({});
  serviceBreakDownObs$ = this.serviceBreakDown.asObservable();

  // This is to refresh notification
  get refreshedNotification() {
    return this.refreshNeeded;
  }

  get refreshDelete() {
    return this.refreshNeeded;
  }

  get refreshAdminList() {
    return this.refreshNeeded;
  }

  get refreshKyc() {
    return this.refreshNeeded;
  }

  get refreshThis() {
    return this.refreshNeeded;
  }

  get lastestUserList() {
    return this.refreshNeeded;
  }



  constructor(
    private http: HttpClient,
    private token: TokenService
  ) { }



  getAllHelp() {
    const params1 = new HttpParams().set('userId', this.token.getid());
    return this.http.get(`${this.baseUrl}/${this.helpCenter}/helprequest`, { params: params1 });
  }

  igetAllHelp() {
    // const params1 = new HttpParams().set('userId', this.token.getid());
    return this.http.get(`${this.baseUrl}/${this.helpCenter}`);
  }

  helpAcknowledge(data) {
    return this.http.put(`${this.baseUrl}/${this.helpCenter}/AckwoledgeResolve`, data);
  }

  createNotification(data: any) {
    return this.http.post(`${this.baseUrl}/${this.notification}`, data)
      .pipe(
        tap(() => {
          this.refreshedNotification.next();
        })
      );
  }

  editNotification(data: any) {
    const userId = new HttpParams().set('userId', this.token.getid());
    return this.http.put(`${this.baseUrl}/${this.notification}`, data, { params: userId })
      .pipe(
        tap(() => {
          this.refreshedNotification.next();
        })
      );
  }

  deleteNotification(id: any) {
    const userId = new HttpParams().set('userId', this.token.getid());
    return this.http.delete(`${this.baseUrl}/${this.notification}/${id}`, { params: userId })
      .pipe(
        tap(() => {
          this.refreshedNotification.next();
        })
      );
  }

  getAllSentNotification() {
    return this.http.get(`${this.baseUrl}/${this.notification}/sentnotifications`);
  }

  getAllScheduleNotification() {
    return this.http.get(`${this.baseUrl}/${this.notification}/AllScheduleNotification`);
  }

  getAllDraftNotification() {
    return this.http.get(`${this.baseUrl}/${this.notification}/AllDraftNotification`);
  }

  getAllTrashNotification() {
    return this.http.get(`${this.baseUrl}/${this.notification}/AllTrashNotification`);
  }

  // Create a property to hold array of users

  private users: any = [];


  // create BehaviorSubject

  private getUserlistSource$ = new BehaviorSubject<any>([]);


  // Get user list
  getUserList$() {
    return this.getUserlistSource$.asObservable();
  }


  /**
   *
   * @returns
   *
   *   Declarative Pattern / PUSH-Based Approach
   *
   */


  // add PUSH Pattern for READ, UPDATE & DELETE admin operations

// NEXT :=> UPDATE ADMIN RECORD
        // DELETE ADMIN RECORD

  private selectedAdminID = new BehaviorSubject<string>(null);

  private adminSubject = new BehaviorSubject<any>([]);
  adminSubjectChanged$ = this.adminSubject.asObservable();


  private adminWithRoleSubject = new BehaviorSubject<any>([]);
  adminWithRoleSubjectChanged$ = this.adminWithRoleSubject.asObservable();



  private getAllAdmins(): Observable<any> {
    return this.http.get(`${this.baseUrl}/${this.role}/UserAndRole`);
  }

  private getAminWithRoles(): Observable<any> {
    return this.http.get(`${this.baseUrl}/${this.role}/UserAndRole`);
  }

   private getAdminById(adminID): Observable<any> {
    const userId = new HttpParams().set('userId', adminID);
    return this.http.get(`${this.baseUrl}/${this.account}/admin`, { params: userId });
  }

  allAdmins$ = this.adminSubject.pipe(
    filter(Boolean),
    switchMap(() => this.getAllAdmins()),
    shareReplay(1)
  );

  allAdminWithRole$ = this.adminWithRoleSubject.pipe(
    filter(Boolean),
    switchMap(() => this.getAminWithRoles()),
    shareReplay(1)
  );

  adminByID$ = this.selectedAdminID.pipe(
    filter(Boolean),
    switchMap(adminID => this.getAdminById(adminID)),
    shareReplay(1)
  );





   // watch for changes
  loadUser() {
    this.adminSubject.next(this.adminSubjectChanged$);
  }

  // watch for changes
  loadSingleAdmin(id: string) {
    this.selectedAdminID.next(id);
  }



   // create Admin User
   createAdminUser(adminUserData) {
    return this.http.post(`${this.baseUrl}/${this.account}/Adminregister`, adminUserData)
    .pipe(
      tap(() => {
        this.loadUser();
      })
    );
  }

  // remove ADMIN user
  removeAdminUser(adminEntity: any) {
    return this.http.post(`${this.baseUrl}/${this.role}/RemoveUser`, adminEntity)
    .pipe(
      tap(() => {
        this.loadUser();
      })
    );
  }




  getAllUsers() {
    return this.http.get(`${this.baseUrl}/${this.account}/allusers`)
  }

  getActiveUser() {
    return this.http.get(`${this.baseUrl}/${this.dashboard}/ActiveUser`);
  }

  getDeactiveUser() {
    return this.http.get(`${this.baseUrl}/${this.dashboard}/DeactivatedUser`);
  }

  getInActiveUser() {
    return this.http.get(`${this.baseUrl}/${this.dashboard}/InActiveUser`);
  }

  getAdminInfo() {
    const userId = new HttpParams().set('userId', this.token.getid());
    return this.http.get(`${this.baseUrl}/${this.account}/admin`, { params: userId });
  }

  getAdminInfoByID(userID: string) {
    const userId = new HttpParams().set('userId', userID);
    return this.http.get(`${this.baseUrl}/${this.account}/admin`, { params: userId });
  }

  getAllAdminUser() {
    return this.http.get(`${this.baseUrl}/${this.account}/AllAdminUsers`);
  }



  // Roles/UserAndRole
  getAllAdminUserWithRole() {
    return this.http.get(`${this.baseUrl}/${this.role}/UserAndRole`);
    // .pipe(
    //   tap(() => {
    //     this.refreshThis.next();
    //   })
    // );
  }


  getRoles() {
    return this.http.get(`${this.baseUrl}/${this.role}`);
  }

  // refreshThis
  updateAdminUser(adminEntity) {
    return this.http.post(`${this.baseUrl}/${this.role}/UpdateUserRole`, adminEntity)
      .pipe(
        tap(() => {
          this.loadUser();
        })
      );
  }


  getTotalDonation() {
    return this.http.get(`${this.baseUrl}/${this.dashboard}/TotalDonation`);
  }

  getYearlyDonationAnalytics(year) {
    const Year = new HttpParams().set('Year', year);
    return this.http.get(`${this.baseUrl}/${this.dashboard}/YearlyAnalytics`, { params: Year });
  }

  getWeeklyDonationAnalytics() {
    return this.http.get(`${this.baseUrl}/${this.dashboard}/WeeklyAnalytics`);
  }

  getDonationList() {
    return this.http.get(`${this.baseUrl}/donation`);
  }

  getAllTTIUsers() {
    return this.http.get(`${this.baseUrl}/${this.tti}/AllRegisteredUsers`);
  }

  getTTIGenderCount(int: any) {
    const RequestType = new HttpParams().set('RequestType', int);
    return this.http.get(`${this.baseUrl}/${this.tti}/MaleFemaleTTICount`, { params: RequestType });
  }

  getRegisteredUserGraphByState(int: any) {
    const RequestType = new HttpParams().set('RequestType', int);
    return this.http.get(`${this.baseUrl}/${this.tti}/RegisteredUserGraphByState`, { params: RequestType });
  }

  getTeacherAndNonTeacherGraph(int: any) {
    const RequestType = new HttpParams().set('RequestType', int);
    return this.http.get(`${this.baseUrl}/${this.tti}/TeacherAndNonTeacherGraph`, { params: RequestType });
  }

  getBreakdownByAgeGraph(int: any) {
    const RequestType = new HttpParams().set('RequestType', int);
    return this.http.get(`${this.baseUrl}/${this.tti}/BreakdownByAgeGraph`, { params: RequestType });
  }

  getWeeklyTTIGraph() {
    return this.http.get(`${this.baseUrl}/${this.tti}/WeeklyTTIGraph`);
  }

  getMonthlyTTIGraph(year: any) {
    const yearly = new HttpParams().set('Year', year);
    return this.http.get(`${this.baseUrl}/${this.tti}/MonthlyTTIGraph`, { params: yearly });
  }

  getYearlyUserAnalytics(year: any) {
    const yearly = new HttpParams().set('year', year);
    return this.http.get(`${this.baseUrl}/${this.dashboard}/YearlyUserAnalytics`, { params: yearly });
  }

  getWeeklyUserAnalytics() {
    return this.http.get(`${this.baseUrl}/${this.dashboard}/WeeklyUserAnalytics`);
  }

  // EdubankNok/UserNextOfKin
  getUserNOKByUserId(id: string) {
    const userID = new HttpParams().set('UserId', id);
    return this.http.get(`${this.baseUrl}/EdubankNok/UserNextOfKin`, { params: userID });
  }

  confirmKyc(data: any) {
    const userId = new HttpParams().set('userId', this.token.getid());
    return this.http.put(`${this.baseUrl}/EdubankKyc/confirmkyc`, data, { params: userId })
      .pipe(
        tap(() => {
          this.refreshKyc.next();
        })
      );
  }


  // fetchCardInfo(): Observable<IDashboardCard> {

  //   if (this.dashBoardCardInfo) {
  //     return of(this.dashBoardCardInfo);
  //   }
  //     const dashboardCardsUri = `${this.baseUrl}/${this.dashboard}/GenericInfo00`;
  //     return this.http.get<IDashboardCard>(dashboardCardsUri)
  //     .pipe(
  //       tap(data => console.log('All Card Info', JSON.stringify(data))),
  //       tap((data) => this.dashboardCardSource.next(data)),
  //       catchError(this.handleError)
  //     );
  // }



  getGenericDataForAdminDashboad() {
    return this.http.get(`${this.baseUrl}/${this.dashboard}/GenericInfo`)
      .pipe(
        tap(genericInfo => {
          // this.dashboardCardSource.next(genericInfo);
          this.dashboardCards.next(genericInfo);
        })
      );
  }

  // ServiceBreakdownAnalytics
  serviceBreakdownAnalytics(int: any) {
    const filtersBy = new HttpParams().set('filtersBy', int);
    return this.http.get(`${this.baseUrl}/${this.dashboard}/ServiceBreakdownAnalytics`, { params: filtersBy })
      .pipe(
        tap(serviceBreakDownInfo => {
          this.serviceBreakDown.next(serviceBreakDownInfo);
        })
      );
  }

  adminLogin(data: any) {
    return this.http.post(`${this.baseUrl}/${this.account}/Adminlogin`, data);
  }

  getAccountGenByFilter() {
    return this.http.get(`${this.baseUrl}/${this.accountGenByFilter}`);
  }


  getAllAuditLog() {
    return this.http.get(`${this.baseUrl}/${this.dashboard}/AllAuditLog`);
  }


  donationCustomRange(data) {
    return this.http.post(`${this.baseUrl}/${this.dashboard}/CustomRangeAnalytics`, data);
  }

  customRangeForServiceBreakdown(data) {
    return this.http.post(`${this.baseUrl}/${this.dashboard}/CustomRangeServiceBreakdown`, data);
  }

  customRangeRegistration(data) {
    return this.http.post(`${this.baseUrl}/${this.dashboard}/CustomRangeRegistration`, data);
  }


  updateUserInfo(userInfo: any) {
    const ID: string = this.token.getid();
    const adminID = new HttpParams().set('adminUserId', ID);
    return this.http.put(`${this.baseUrl}/${this.account}/UpdateProfileByAdmin`, userInfo, { params: adminID });
  }


  accountGenerated(info: {}) {
    return this.http.post(`${this.baseUrl}/${this.sterlingCustm}/AccountGeneratedData`, info);
  }

  // /api/SterlingCustomer/

  accountReqByService(serviceType: number) {
    return this.http.get(`${this.baseUrl}/${this.sterlingCustm}/AccountRequestLocationByService`);
  }


  getAccountReqByService(serviceType: {}) {
    return this.http.post(`${this.baseUrl}/${this.sterlingCustm}/AccountGeneratedData`, serviceType);
  }

  // GET customer Profile

  getCustomerProfileRequest() {
    return this.http.get(`${this.baseUrl}/${this.customer_profile}`);
  }



  private handleError(err: HttpErrorResponse) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Backend returned code ${err.status}, body was: ${err.error}`;
    }
    // console.error(err);
    return throwError(errorMessage);
  }


}
