import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-landing',
  templateUrl: './footer-landing.component.html',
  styleUrls: ['./footer-landing.component.scss']
})
export class FooterLandingComponent implements OnInit {

  constructor() { }

  displayYear: number;



  // this.year = new Date().getUTCFullYear();

// console;.log('[Current Year is]:', this.year);

getYear = () => {
  const year = new Date().getFullYear();
  // console.log('[Current Year is]: ', year);
  return year;
}

ngOnInit() {
  this.displayYear = this.getYear();
  // console.log('[Display year returns]: ', this.displayYear);
  }

}
