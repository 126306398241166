import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobileapp-download',
  templateUrl: './mobileapp-download.component.html',
  styleUrls: ['./mobileapp-download.component.scss']
})
export class MobileappDownloadComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
