import { Component, OnInit } from '@angular/core';
import { LocationStrategy } from '@angular/common';



import { BnNgIdleService } from 'bn-ng-idle';
import { AuthService } from './shared/services/auth/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConnectionService } from 'ng-connection-service';
import { GoogleAnalyticsService } from './shared/services/googleAnalytics/google-analytics.service';
import { TokenService } from './shared/services/token/token.service';

declare let gtag: (...params: any[]) => void;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'EduBanc';
  status = 'ONLINE';
  isConnected = true;


  constructor(private bnIdle: BnNgIdleService,
              private location: LocationStrategy,
              private connectionService: ConnectionService,
              private authSrv: AuthService,
              private toastrSrv: ToastrService,
              private appTokenSrv: TokenService,
              private googoleAnalyticSrv: GoogleAnalyticsService,
              private router: Router) {

                // router.canceledNavigationResolution = 'computed';
                history.pushState(null, null, window.location.href);  
                this.location.onPopState(() => {
                  history.pushState(null, null, window.location.href);
                });

                this.connectionService.monitor().subscribe(isConnected => {
                  this.isConnected = isConnected;
                  if (this.isConnected) {
                    this.status = 'ONLINE';
                  } else {
                    this.status = 'OFFLINE';
                    this.toastrSrv.info('You seem offline, Kindly check your internet connection', 'Internet Connection');
                  }
                });

                router.events.subscribe((y: NavigationEnd) => {
                  if (y instanceof NavigationEnd) {
                    gtag('config', 'GTM-WRSSNMF', {page_path: y.urlAfterRedirects, send_page_view: true});
                    this.googoleAnalyticSrv.pageViewEmiter(y.urlAfterRedirects);
                  }
                });
               }


               clearStoreAndResetAuth() {
                localStorage.clear();
                this.authSrv.changeAuthStatus(false);
               }

              cleanUpStorageAndRedirect() {
                if (this.appTokenSrv.getUserRole() !== 'User') {
                  this.clearStoreAndResetAuth(); 
                  this.router.navigate(['/admin/login']);
                } else {
                  this.clearStoreAndResetAuth();
                  this.router.navigate(['/sign-in']);
                }
              }


  ngOnInit() {
    this.bnIdle.startWatching(300).subscribe((isTimedOut) => {
      if (localStorage.hasOwnProperty('token') && isTimedOut) {
        this.cleanUpStorageAndRedirect();
        this.router.routeReuseStrategy.shouldReuseRoute = () => {
          return false;
        };
      }
    });
  }
}
