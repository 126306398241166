import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-preload',
  template: `
    <div [ngStyle]="getMyStyles()" class="skelt-load loader"></div>
    `,
  styleUrls: ['./preload.component.scss']
})
export class PreloadComponent implements OnInit {

  constructor() { }

  @Input() cWidth;
  @Input() cHeight;
  @Input() circle: boolean;

  ngOnInit() { }

  getMyStyles(): any {
    const styles = {
       'width.px': this.cWidth ? this.cWidth :  '',
       'height.px': this.cHeight ? this.cHeight : '',
       'border-radius': this.circle ? '50%' : ''
    };
    return styles;
}

}
