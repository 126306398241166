import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TokenService } from '../token/token.service';


@Injectable({
  providedIn: 'root'
})
export class ProductService {

  // base URL segment
  private eduBancBaseUrl = environment.baseUrl;
  private productResource = 'ProductUser';

  // controller methods URL fragment
  private getMyEdubancProduct = 'ProductForUsers';

  constructor(private http: HttpClient,
              private appTokenSrv: TokenService) { }

  addProductSelectedByUser(productObj) {
    return this.http.post(`${this.eduBancBaseUrl}/${this.productResource}`, productObj);
  }

  getUserProducts() {
    const userID = new HttpParams().set('userId', this.appTokenSrv.getid());
    return this.http.get(`${this.eduBancBaseUrl}/${this.productResource}/${this.getMyEdubancProduct}`, { params: userID});
  }
}
