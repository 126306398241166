import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortNexfordPaymentByType'
})
export class SortNexfordPaymentByTypePipe implements PipeTransform {

  transform(value: any, paymentType: any): any[] {
    if (!value) {
      return [];
    }
    if (!paymentType) {
      return value;
    }

    return value.filter((item: any) => {
      return Object.keys(item.mode).some((key: any) => {
        console.log(item.mode);
        console.log(item.mode[key]);
        console.log(paymentType);
        console.log(String(item.mode[key]).toLowerCase().includes(paymentType.toLowerCase()));
        return String(item.mode[key]).toLowerCase().includes(paymentType.toLowerCase());
      });
    });
  }

}
