import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { HomeService } from 'src/app/shared/services/home.service';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { ApiCallService } from 'src/app/shared/services/api/api-call.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { TokenService } from 'src/app/shared/services/token/token.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TitleService } from 'src/app/shared/services/title/title.service';

declare var $: any;
declare var smartech: any;


@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit, AfterViewInit, OnDestroy {

  ngUnSubscribe$: Subject<void> = new Subject<void>();

  constructor(private homeSrv: HomeService,
              private fb: FormBuilder,
              private apiCall: ApiCallService,
              private router: Router,
              private authSrv: AuthService,
              private token: TokenService,
              private toastrSrv: ToastrService,
              private pageTitle: TitleService
  ) { }


  // filterElementRef: ElementRef;
  @ViewChild('filterElement', { static: false }) filterElementRef: ElementRef;

  verifyAccountForm: FormGroup;
  convertToArray: any;
  mergeConvertedArray: any;
  counter: number;
  userOTP: { token, email };
  resendOTP: { email: string, signUpEnum: number };
  displayResendTokenLink = false;
  hideCounter = false;
  verifyTokenErrMsg: any;
  tokenToSet: any;
  showTimer = false;
  showOTPResend = false;

  resendInProgress = false;
  showCounter = false;

  isLoading = false;

  fetchUserRegEmail() {
    return localStorage.getItem('emailForOTP') || localStorage.getItem('userEmail');
  }

  /**
   * Handles Success response or 200 0K
   * Used in the sign in next callback for Observable returned
   * @ param data: Observable returned from the Server
   */
  setUserTokenToStorage(data) {
    this.token.handle(data);
    this.authSrv.changeAuthStatus(true);
  }

  ngAfterViewInit(): void {
    if (this.filterElementRef.nativeElement) {
      this.filterElementRef.nativeElement.focus();
    }
  }

  resetVerifyForm = () => this.verifyAccountForm.reset();


  stopCounter() {
    this.counter = 0;
    return;
  }

  onVerifyAccount() {
    if (!this.verifyAccountForm.valid) {
      return;
    }
    this.convertToArray = Object.values(this.verifyAccountForm.value);
    this.mergeConvertedArray = this.convertToArray.join('');

    this.userOTP = {
      token: +(this.mergeConvertedArray),
      email: localStorage.getItem('emailForOTP') || localStorage.getItem('userEmail')
    };


    // console.log('Data going to server===>', this.userOTP);
    const emailForSmartech = this.fetchUserRegEmail();
    this.isLoading = true;
    this.apiCall.verify(this.userOTP).pipe(takeUntil(this.ngUnSubscribe$)).subscribe(
      (response) => {
        this.isLoading = false;


        // smartech
        // smartech('identify', emailForSmartech);
        // smartech('dispatch', 'Sign_Up_completed_email_verification', {
        //   EMAIL: emailForSmartech,
        //   REASON_FOR_FAILURE: ''
        // });
        this.resetVerifyForm();
        this.toastrSrv.success('Your account verification was successful.', 'Success');
        this.router.navigateByUrl('/sign-in');
      },
      (err) => {
        this.isLoading = false;
        console.log('[Error Message from Verify]: ', err);
        this.verifyTokenErrMsg = err.error;
        // smartech
        // smartech('identify', emailForSmartech);
        // smartech('dispatch', 'Sign_Up_completed_email_verification', {
        //   EMAIL: emailForSmartech,
        //   REASON_FOR_FAILURE: this.verifyTokenErrMsg
        // });

      }
    );

  }



  seconds = 60;
  startCountdown(seconds) {
    this.resendOTPtoUser();
    this.showCounter = true;
    this.resendInProgress = true;
    this.counter = seconds;

    const interval = setInterval(() => {
      this.counter--;
      if (this.counter <= 0) {
        clearInterval(interval);
        this.showCounter = false;
        this.resendInProgress = false;
        // this.resendOTPtoUser();
      }
    }, 1000);
  }

  startCounter(seconds = 60) {
    this.showTimer = true;
    this.counter = seconds;

    const interval = setInterval(() => {
      this.counter--;
      if (this.counter <= 0) {
        clearInterval(interval);
        this.showTimer = false;
        this.showOTPResend = true;
        this.resendInProgress = false;
      }
    }, 1000);
  }

  // data needed for resend of OTP
  resendOTPtoUser() {
    this.displayResendTokenLink = false;
    this.resetVerifyForm();
    this.resendOTP = {
      email: this.fetchUserRegEmail(),
      signUpEnum: 0
    };

    this.apiCall.resendToken(this.resendOTP).pipe(takeUntil(this.ngUnSubscribe$)).subscribe(
      (response) => {
        // console.log(response);
        // Add snackbar here or toastr
        this.toastrSrv.success('Check your email for new OTP', 'Token Sent!');
      },
      (err) => {
        this.counter = 0;
        console.log('ResendOTP Error: ', err);
        // this.toastrSrv.error(err.error, 'Failed Request!');
        this.verifyTokenErrMsg = err.error;
      }
    );
  }

  ngOnInit() {

    this.startCounter();
    this.pageTitle.setTitle('Verify');

    this.verifyAccountForm = this.fb.group({
      digit1: ['', [Validators.required]],
      digit2: ['', [Validators.required]],
      digit3: ['', [Validators.required]],
      digit4: ['', [Validators.required]],
      digit5: ['', [Validators.required]],
      digit6: ['', [Validators.required]],
    });


    $(document).ready(function () {
      $('.inputsNext').keyup(function () {
        const $this = $(this);
        if ($this.val().length >= $this.data('maxlength')) {
          if ($this.val().length > $this.data('maxlength')) {
            $this.val($this.val().substring(0, 1));
          }
          $this.next('.inputsNext').focus();
        }
      });
    });
  }

  otpInput() {
    $('.digit-group').find('input').each(() => {
      $(this).attr('maxlength', 1);
      $(this).on('keyup', (e) => {
        const parent = $($(this).parent());

        if (e.keyCode === 8 || e.keyCode === 37) {
          const prev = parent.find('input#' + $(this).data('previous'));

          if (prev.length) {
            $(prev).select();
          }
        } else if ((e.keyCode >= 48 && e.keyCode <= 57) ||
          (e.keyCode >= 65 && e.keyCode <= 90) ||
          (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 39) {
          const next = parent.find('input#' + $(this).data('next'));

          if (next.length) {
            $(next).select();
          } else {
            if (parent.data('autosubmit')) {
              parent.submit();
            }
          }
        }
      });
    });
  }


  ngOnDestroy(): void {
    this.ngUnSubscribe$.next();
    // console.log('[VerifyComponent]: Unsubscribing!');
    this.ngUnSubscribe$.complete();
  }
}
