// Production Environment credential
export const environment = {
  production: true,
  baseUrl: 'https://edubancapi.edubanc.ng/api',
  url: 'https://edubanc.ng',
  adminUrl: 'https://edubanc.ng/admin/login',
  fbKey: '609336489801950',
  GlKey: '561411520926-rbo1c137t0tg7mtj8simouel2rvttfq8.apps.googleusercontent.com',
  paymentGateway: 'https://www.switch-ng.com/sterlinggateway?q=',
  oneMTURL: 'https://start.1millionteachers.com/dashboard/?autologin_code=',
  edupayUrl: 'https://edpay.edubanc.ng/api',
  edpayLink: 'https://loan.edubanc.ng',
  encryptKey : '1203199320052021',
  encryptIV: '1203199320052021'
};