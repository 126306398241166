import { GoogleAnalyticsService } from './services/googleAnalytics/google-analytics.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DateAgoPipe } from './pipe/dateAgo/date-ago.pipe';
import { HeaderLandingComponent } from '../layouts/landing/header-landing/header-landing.component';
import { HomeService } from './services/home.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SortWithProductPipe } from './pipe/sort-with-product/sort-with-product.pipe';
import { FilterPipe } from './pipe/filter/filter.pipe';
import { SortWithServicenamePipe } from './pipe/sort-with-servicename/sort-with-servicename.pipe';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { SinglNumberComponent } from './custom-component/singl-number/singl-number.component';
import { TableListComponent } from './custom-component/table-list/table-list.component';
import { SinglTextComponent } from './custom-component/singl-text/singl-text.component';
import { SinglNumTextComponent } from './custom-component/singl-num-text/singl-num-text.component';
import { MobileappDownloadComponent } from './mobileapp-download/mobileapp-download.component';
import { PreloadComponent } from './preload/preload.component';
import { FormloaderComponent } from './custom-component/formloader/formloader.component';
import { DashboardLoaderComponent } from './custom-component/dashboard-loader/dashboard-loader.component';
import { ConvertFirstToUpperPipe } from './custom-validator/convert-string-toUppercase';
import { SortNexfordPaymentByDatePipe } from './pipe/sort-nexford-payment-by-date/sort-nexford-payment-by-date.pipe';
import { SortNexfordPaymentByTypePipe } from './pipe/sort-nexford-payment-by-type/sort-nexford-payment-by-type.pipe';

const modules = [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ContentLoaderModule
]

@NgModule({
  declarations: [
    DateAgoPipe,
    HeaderLandingComponent,
    ConvertFirstToUpperPipe,
    FilterPipe,
    SortWithProductPipe,
    SortWithServicenamePipe,
    SinglNumberComponent,
    TableListComponent,
    SinglTextComponent,
    SinglNumTextComponent,
    MobileappDownloadComponent,
    PreloadComponent,
    FormloaderComponent,
    DashboardLoaderComponent,
    SortNexfordPaymentByDatePipe,
    SortNexfordPaymentByTypePipe
  ],
  imports: [...modules],
  exports: [...modules,
    DateAgoPipe,
    HeaderLandingComponent,
    FilterPipe,
    SortWithProductPipe,
    ConvertFirstToUpperPipe,
    SortWithServicenamePipe,
    SinglNumberComponent,
    TableListComponent,
    SinglTextComponent,
    SinglNumTextComponent,
    MobileappDownloadComponent,
    PreloadComponent,
    FormloaderComponent,
    DashboardLoaderComponent,
    SortNexfordPaymentByDatePipe,
    SortNexfordPaymentByTypePipe
  ],
  providers: [
    HomeService,
    GoogleAnalyticsService
  ]
})
export class SharedModule { }
