import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../../shared/services/auth/auth.service';
import { Subject } from 'rxjs';
import { AdminApiService } from '../../../shared/services/api/admin-api.service';
import { takeUntil } from 'rxjs/operators';
import { TitleService } from 'src/app/shared/services/title/title.service';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss']
})
export class AdminHeaderComponent implements OnInit, OnDestroy {
  title: any;
  adminInfo: any;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private authSrv: AuthService,
    private adminApi: AdminApiService,
    private pageTitle: TitleService
  ) { }

  ngOnInit() {
    this.getAdminInfo();

    this.pageT();
  }

  logout() {
    // this.authSrv.logout();
    this.authSrv.adminLogout();
  }

  getAdminInfo() {
    this.adminApi.getAdminInfo().pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.adminInfo = data;
      }
    );
  }

  pageT() {
    this.pageTitle.getTitle().subscribe(
      data => {
        this.title = data;
      }
    );
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    // console.log('[AdminHeaderComponent]: unsubscribing from Subscriptions!');
    this.ngUnsubscribe.complete();
  }

}
