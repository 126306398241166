import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';

// Services
import { HomeService } from 'src/app/shared/services/home.service';
import { TokenService } from 'src/app/shared/services/token/token.service';
import { ApiCallService } from 'src/app/shared/services/api/api-call.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';

// RxJs
import { debounceTime, takeUntil, take } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from 'src/app/shared/services/product/product.service';
import { AccountService } from 'src/app/shared/services/account/account.service';
import { environment } from 'src/environments/environment';
import { NexfordApiService } from 'src/app/shared/services/api/nexford/nexford-api.service';
import { GoogleAnalyticsService } from 'src/app/shared/services/googleAnalytics/google-analytics.service';

declare var $: any;
@Component({
  selector: 'app-header-landing',
  templateUrl: './header-landing.component.html',
  styleUrls: ['./header-landing.component.scss']
})
export class HeaderLandingComponent implements OnInit, OnDestroy {
  tokings: any;
  fieldTextType: boolean;
  fieldTextTypeUp: boolean;

  ngUnsubscribe: Subject<void> = new Subject<void>();
  userNeedsVerification = false;

  serviceName: string;
  serviceNames: any;

  notificationsArr: any;
  // tslint:disable-next-line:no-inferrable-types
  showBellIcon: boolean = false;
  public isOnNexford: boolean;



  constructor(private homeSrv: HomeService,
              private fb: FormBuilder,
              private token: TokenService,
              private apiCall: ApiCallService,
              private router: Router,
              private productSrv: ProductService,
              private acctHttpSrv: AccountService,
              private nexfordApi: NexfordApiService,
              private toastrSrv: ToastrService,
              private googoleAnalyticSrv: GoogleAnalyticsService,
              private authSrv: AuthService
              ) { }

  // JWT helper
  helper = new JwtHelperService();

  // Object to request for new Verification code
  resendOTP: { email: string, signUpEnum: number };

  // Getter method for prop displayLoginForm
  // It controls the SHOW or HIDE for the
  // loginForm...either it shows or the SignUp shows
  get displayLoginForm(): boolean {
    return this.homeSrv.displayLoginForm;
  }

  // This sets the type of value expected for
  // prop displayLoginForm
  set displayLoginForm(value: boolean) {
    this.homeSrv.displayLoginForm = value;
  }

  // Getter method for prop displaySignUpForm
  // It controls the SHOW or HIDE for the
  // signUpForm...either it shows or the signIn shows
  get displaySignUpForm(): boolean {
    return this.homeSrv.displaySignUpForm;
  }

  set displaySignUpForm(value: boolean) {
    this.homeSrv.displaySignUpForm = value;
  }

  get displayVerifcation(): boolean {
    return this.homeSrv.displayVerification;
  }

  set displayVerifcation(value: boolean) {
    this.homeSrv.displayVerification = value;
  }

  get preserveEmailForVerification(): string {
    return this.homeSrv.preserveEmailForVerification;
  }

  set preserveEmailForVerification(value: string) {
    this.homeSrv.preserveEmailForVerification = value;
  }

  get isTypeOfUser() {
    if (this.token.roleMatch(['User'])) {
      return true;
    }
  }

  // Init Sign-up and sign-in form as a FormGroup
  userSignUpForm: FormGroup;
  userSignInForm: FormGroup;

  // Declare or initialize component properties
  emailFeedbackMsg: string;
  userSignUpDetail: {
    title: '',
    lastName: '',
    firstName: '',
    email: '',
    password: '',
    confirmPassword: '',
    signUpEnum: 0,
    phoneNumber: '',
    gender: string,
    termsAndCondition: true
  };

  loginResponse: any;
  signUpEnumForLogin = {
    signUpEnum: 0
  };
  erorrMsg: any;
  signinHasError = false;
  signInErrorMsg: string;
  phone = '^[0-9]*$';

  signUpErrorMsg: string;
  signUpHasError =  false;

  userInfo: any;
  adminInfo: any;
  userToken: any;
  isLoading = false;
  userProducts: any;

  notificationObj: any;



  // Returns validation message based on rule e.g required rule
  // Used when watching for changes in email input field
  private validateMessage = {
    required: 'Email is required',
    email: 'Invalid Email'
  };

  userData: {
    email: string,
    signUpEnum: number
  };

  isAuthenticated() {
    return this.token.loggedIn();
  }

  displayVerificationMessage() {
    this.displayLoginForm = false;
    this.displaySignUpForm = false;
    this.displayVerifcation = true;
  }

  /**
   *  click event when a User clicks the login link
   */
  showLogin() {
    this.displaySignUpForm = false;
    this.displayVerifcation = false;
    this.displayLoginForm = true;
  }

  /**
   *  Click event when user clicks the signup link
   */
  showSignUpForm() {
    this.displayLoginForm = false;
    this.displayVerifcation = false;
    this.displaySignUpForm = true;
  }

  /**
   * Clears all form control
   * After form submit
   */
  clearAllFormControl() {
    this.userSignUpForm.enable();
    this.userSignUpForm.reset();
    this.userSignInForm.enable();
    this.userSignInForm.reset();

    if (this.signinHasError) {
      this.signinHasError = false;
    }
  }

  fetchUserNotifications() {
    this.acctHttpSrv.getNotificationsForUser()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (notifications) => {
          this.notificationObj = notifications;

          // console.log('[Checking for Notification]:', this.notificationObj);
          this.notificationsArr = notifications;


          if (this.notificationObj.length > 0) {
            this.showBellIcon = true;
          }

          // console.log('[Notification Array]:', this.notificationsArr);
          this.notificationObj.map(newArr => {
            this.notificationObj = newArr.notifications;
            this.serviceNames = this.getnotificationProductType(this.notificationObj.notificationProductType);
          });
        }
        // (notificationError) => {
        //   console.log('[Notification Error]:', notificationError);
        // }
      );
  }

  getnotificationProductType(productIdentifier: number) {
    switch (productIdentifier) {
        case 0:
          return this.serviceName = 'ED';
          break;

        case 1:
          return this.serviceName = 'EI';
          break;

        case 2:
          return this.serviceName = 'EC';
          break;

        case 3:
          return this.serviceName = 'EP';
          break;

        case 4:
          return this.serviceName = 'AM';
          break;

        case 5:
          return this.serviceName = 'TT';
          break;

        default:
          break;
    }

  }


  getGenderByTitle() {
    const title = this.userSignUpForm.get('title').value;
    return title === 'Mrs' || title === 'Miss' || title === 'Ms' ? 'Female' : 'Male';
  }

  /**
   * Submit method that fires when user clicks on submit btn
   * @ returns An Observable which determines where User goes next
   * if User is successful, User will need to Verify their acct
   * or
   * User sees an error message or toast
   */
  onSubmitSignUp() {

    if (!this.userSignUpForm.valid) {
      return;
    }
    // Collect User detail into userSignUpDetail Obj
    this.userSignUpDetail = {
      title: this.userSignUpForm.get('title').value,
      lastName: this.userSignUpForm.get('lastName').value,
      firstName: this.userSignUpForm.get('firstName').value,
      email: this.userSignUpForm.get('email').value,
      password: this.userSignUpForm.get('password').value,
      confirmPassword: this.userSignUpForm.get('password').value,
      signUpEnum: 0,
      phoneNumber: this.userSignUpForm.get('phoneNumber').value,
      gender: this.getGenderByTitle(),
      termsAndCondition: true
    };

    this.preserveEmailForVerification = this.userSignUpDetail.email;
    this.homeSrv.setEmail();
    this.isLoading = true;
    this.apiCall.signup(this.userSignUpDetail).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      () => {
        this.isLoading = false;
        this.clearAllFormControl();
        this.slideOutOfView();
        this.router.navigateByUrl('/verify');
      },
      (error) => {
        this.signUpHasError = true;
        this.erorrMsg = error;
        this.signUpErrorMsg = error.error;
        this.isLoading = false;
      }
    );

  }

  /**
   *  Sets feedback message for form control validation
   *   @ param c: it means it retunrs the form control
   *  In this case, the email form control
   */
  setFeedbackMessage(c: AbstractControl): void {
    this.emailFeedbackMsg = '';
    if ((c.touched || c.dirty) && c.errors) {
      this.emailFeedbackMsg = Object.keys(c.errors).map(
        key => this.emailFeedbackMsg += this.validateMessage[key]).join(' ');
    }
  }

  fetchUserRegEmail() {
    return this.homeSrv.fetchUserRegEmailFromStorage();
  }

  fetchUserData() {
    this.apiCall.getUser().pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (user) => {
        if (user) {
          this.userInfo = user;

          if (this.userInfo.image !== null) {
            this.userInfo.image =  `data:image/png;base64,${this.userInfo.image}`;
          }
        }
      },
      (err) => {
        console.warn('Internal Server Error: No User');
      }
    );
  }

  getUserProducts() {
    this.productSrv.getUserProducts().pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      response => {
        this.userProducts = response;
        this.getNexfordProspect();
      },
      error => {
        // console.log('Could Not Fetch List of User Product: ', error.error);
        const err = error;
      }
    );
  }

  getNexfordProspect() {
    this.nexfordApi.getNexfordProspect().subscribe(
      (success: any) => {
        this.isOnNexford = true;
      },
      error => {
        this.isOnNexford = false;
      }
    );
  }


  /**
   * on init
   */
  ngOnInit() {


    if (localStorage.hasOwnProperty('token')) {
      this.fetchUserNotifications();
    }

    if (localStorage.hasOwnProperty('token')) {
      this.fetchUserData();
    }

    if (localStorage.hasOwnProperty('token')) {
      this.apiCall.refreshUserInfo.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
        this.fetchUserData();
      });
    }

    if (localStorage.hasOwnProperty('token')) {
      this.getUserProducts();
    }

    const allowedPasswordFormatRegex = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$';
    this.userSignUpForm = this.fb.group({
      title: ['', [Validators.required]],
      firstName: ['', [Validators.required, Validators.minLength(3)]],
      lastName: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(allowedPasswordFormatRegex)]],
      phoneNumber: ['', [Validators.required, Validators.minLength(11)]]
    });


    this.userSignInForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });

    // Watch for changes in the email formControl
    // Subscribe to it to extract data or throw Exception
    const signInEmail = this.userSignInForm.get('email');
    signInEmail.valueChanges.pipe(
      debounceTime(1000)).pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => this.setFeedbackMessage(signInEmail));

    // Watch for changes in the email formControl
    // Subscribe to it to extract data or throw Exception
    const emailControl = this.userSignUpForm.get('email');
    emailControl.valueChanges.pipe(
      debounceTime(1000)).pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => this.setFeedbackMessage(emailControl));


    // Authentication Jquery Code
    $(document).ready(() => {

      $('#dismiss, .overlay').on('click', () => {
        $('#sidebar').removeClass('active');
        $('.overlay').removeClass('active');
      });

      $('#sidebarCollapse').on('click', () => {
        $('#sidebar').addClass('active');
        $('.overlay').addClass('active');
        $('.collapse.in').toggleClass('in');
        $('a[aria-expanded=true]').attr('aria-expanded', 'false');
      });


      $('#sidebarCollapsez').on('click', () => {
        $('#sidebar').addClass('active');
        $('.overlay').addClass('active');
        $('.collapse.in').toggleClass('in');
        $('a[aria-expanded=true]').attr('aria-expanded', 'false');
      });
    });

  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  toggleFieldTextTypeUp() {
    this.fieldTextTypeUp = !this.fieldTextTypeUp;
  }


  closeAllActiveOverlay() {
    $(document).ready(() => {
      $('.overlay').removeClass('active');
    });
  }

  slideOutOfView() {
    $('.overlay').removeClass('active');
    $('#sidebar').removeClass('active');
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Determines whether to log user in or not..
   * @ returns token
   */
  onLogUserIn() {

    if (!this.userSignInForm.valid) {
      return;
    }

    const userEmail = this.userSignInForm.get('email').value;
    this.homeSrv.setEmailFromLogin(userEmail);
    this.isLoading = true;
    const userCredential = { ...this.userSignInForm.value, ...this.signUpEnumForLogin };
    this.apiCall.login(userCredential).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (res) => {
        if (res) {
          this.isLoading = false;
          this.slideOutOfView();
          this.homeSrv.displayLoginForm = false;
          this.loginResponse = res;
          this.userSignInForm.reset();
          this.setLoginResDataToStorage(this.loginResponse);
          this.gotoUserDashboard();

          if (!this.isTypeOfUser) {
            this.gotoAdmimPage();
          }
        }
      },
      (error) => {
        // console.log('Failed to login user bcos:', JSON.stringify(error));
        this.isLoading = false;
        this.signinHasError = true;
        this.signInErrorMsg = error.error;

        const verificationNeededError = 'Your account is yet to be verified. Please click on the button below to get a verification code.';
        if (this.signInErrorMsg === verificationNeededError) {
          this.userNeedsVerification = true;
          this.userSignInForm.disable();
        }
      }
    );
  }

  reqForVerifCodeOnSignIn() {
    const email = this.userSignInForm.get('email').value;
    this.resendOTP = { email, signUpEnum: 0 };
    this.apiCall.resendToken(this.resendOTP).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (response) => {
        console.log(response);
        this.clearAllFormControl();
        this.slideOutOfView();
        this.router.navigateByUrl('/verify');
        this.toastrSrv.success('Check your email for new verification code', 'Token Sent!');
      },
      (err) => {
        this.toastrSrv.error('Could not send OTP, contact admin', 'Failed Request!');
      }
    );
  }

  movetoEducationLoan(buttonType): void {
    if (buttonType === 'edpay') {
      // const externalUrl = 'https://edupayfrontend.azurewebsites.net/?';
      const externalUrl = environment.edpayLink; // 'http://localhost:4300/?';
      const token = 'qt=' + this.token.get();
      const refreshToken = 'qr=' + this.token.getRefresh();
      const expiry = 'qex=' + this.token.getExpiry();
      const error = 'qer=' + this.token.getRefresh();
      const otherSchool = 'qos=' + false;
      // window.open(externalUrl + '/?' + token + '&' + refreshToken + '&' + expiry + '&' + error, '_blank');

      window.open(externalUrl + '/?' + token + '&' + refreshToken + '&' + expiry + '&' + error + '&' + otherSchool, '_self');
    } else if (buttonType === 'nexford') {
      const externalUrl = environment.edpayLink;
      const token = 'qt=' + this.token.get();
      const refreshToken = 'qr=' + this.token.getRefresh();
      const expiry = 'qex=' + this.token.getExpiry();
      const error = 'qer=' + this.token.getRefresh();
      const otherSchool = 'qos=' + true;
      window.open(externalUrl + '/?' + token + '&' + refreshToken + '&' + expiry + '&' + error + '&' + otherSchool, '_self');
    }
  }

  /**
   * Handles Success response or 200 0K
   * Used in the sign in next callback for Observable returned
   * @ param data: Observable returned from the Server
   */
  setLoginResDataToStorage(data) {
    this.token.handle(data);
    this.authSrv.changeAuthStatus(true);
  }

  gotoAdmimPage() {
    this.router.navigateByUrl('/admin-dashboard');
  }

  gotoUserDashboard() {
    this.router.navigateByUrl('/dashboard');
  }

  setPageAnalytics(name: string, category: string, label: string) {
    this.googoleAnalyticSrv.eventEmiter(name, category, label);
  }

  logout() {
    this.authSrv.logout();
  }


}
