import { PipeTransform, Pipe } from '@angular/core';


@Pipe({
    name: 'convertFirstToUpperCase'
})

export class ConvertFirstToUpperPipe implements PipeTransform {

  transform(value: string): string {
    let convertAllToLower = value.toLowerCase();
    return convertAllToLower.charAt(0).toUpperCase() + convertAllToLower.slice(1);
  }
}
