import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  displayLoginForm = false;
  displaySignUpForm = false;
  displayVerification = false;

  preserveEmailForVerification: string;


  setEmail() {
    localStorage.setItem('userEmail', this.preserveEmailForVerification);
  }

  setEmailFromLogin(email) {
    localStorage.setItem('userEmail', email);
  }

  fetchUserRegEmailFromStorage() {
    return localStorage.getItem('userEmail');
  }

  removeUserEmailFromSrotage() {
    localStorage.removeItem('userEmail');
  }

  constructor() { }
}
 