import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatWithCommas'
})
export class FormatWithCommasPipe implements PipeTransform {

  transform(numericValue: any, argss: any): any {
    return numericValue.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  }

}
