import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortWithServicename'
})
export class SortWithServicenamePipe implements PipeTransform {

  transform(items: any[], service: any): any[] {
    if (!items) {
      return [];
    }
    if (!service) {
      return items;
    }
    // return items.filter((item: any) => {
    //   console.log(item.serviceName);
    //   return item.serviceName.toLowerCase().includes(service.toLowerCase());
    // });
    return items.filter((item: any) => {
      return Object.keys(item.serviceName).some((key: any) => {
        // console.log(item.serviceName);
        return String(item.serviceName[key]).toLowerCase().includes(service.toLowerCase());
      });
    });
  }

}
