import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { TokenService } from '../token/token.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private refreshNeeded = new Subject<void>();

  get refreshRedirect() {
    return this.refreshNeeded;
  }

  get refreshKYCUploads() {
    return this.refreshNeeded;
  }

  get refreshNokData() {
    return this.refreshNeeded;
  }

  get refreshSterlingCustomerInfo() {
    return this.refreshNeeded;
  }


  private eduBancBaseUrl = environment.baseUrl;
  private sterlingCustomerResource = 'SterlingCustomer';
  private verifyUserAccountFor1MT = 'VerifySmsAddAccount';
  private resendOTP = 'ResendSmsCode';
  private account = 'Account';

  // 1MT RELATED..
  private OneMillionTeacher = 'OneMillionTeacher';
  private OneMillionTeacherUser = 'AutoLoginUrlCode';
  private createStudentAcct = '1MTRegistrationThinkific';
  private autoLogIn = 'AutoLogin';
  private OneMTautologinURL = 'AutoLoginUrlCode';
  private oneMTurl = environment.oneMTURL;

  // ERIT
  // private erit = 'Erit';

  // /EdubankAccountOpening/UserAccount

  // /api/SterlingCustomer/UserSterlingAccount

  // EdubankNok/UserNextOfKin

  // Account Opening
  private accountOpeningResource = 'EdubankAccountOpening';
  private verifyMyBVN = 'VerifyBVN';

  // Notification

  private notification = 'Notification';

  constructor(private http: HttpClient,
              private token: TokenService
  ) { }

  sharedOccupation: string;
  sharedTrncValue: string;
  preservedOTP: any;
  perservedEmail: string;

  getSterlingCustomerInfo(customerInfo) {
    return this.http.post(`${this.eduBancBaseUrl}/${this.sterlingCustomerResource}/GetCustomerInfo`, customerInfo);
  }

  getUserAccount() {
    const userID = new HttpParams().set('UserId', this.token.getid());
    return this.http.get(`${this.eduBancBaseUrl}/${this.sterlingCustomerResource}/EdubancAccountNumber`, { params: userID });
  }


  verifyAcctForOneMT(customerInfo) {
    return this.http.post(`${this.eduBancBaseUrl}/${this.sterlingCustomerResource}/${this.verifyUserAccountFor1MT}`, customerInfo);
  }

  // ForgetPassword
  resetUserPassword(resetInfo) {
    return this.http.post(`${this.eduBancBaseUrl}/${this.account}/ForgetPassword`, resetInfo);
  }

  resendOTPbySms(customerPhoneNumber: string) {
    const mobileNumber = new HttpParams().set('MobileNumber', customerPhoneNumber);
    return this.http.get(`${this.eduBancBaseUrl}/${this.sterlingCustomerResource}/${this.resendOTP}`, { params: mobileNumber });
  }

  // 1MillionTeacher Server Calls
  getUserFromOneMillionTeacher() {
    const userID = new HttpParams().set('userId', this.token.getid());
    return this.http.get(`${this.eduBancBaseUrl}/${this.OneMillionTeacher}/UserThinkific`, { params: userID });
  }

  createSterlingStudentOnOneMT(studentInfo) {
    const userID = new HttpParams().set('userId', this.token.getid());
    return this.http.post(`${this.eduBancBaseUrl}/${this.OneMillionTeacher}/${this.createStudentAcct}`, studentInfo, { params: userID });
  }

  autoLoginToOneMT(userId: string) {
    const userID = new HttpParams().set('UserId', userId);
    return this.http.get<string>(`${this.eduBancBaseUrl}/${this.OneMillionTeacher}/AutoLoginUrlCodeThinkific`, { params: userID });
  }

  redirectToOneMT(response: any) {
    return this.http.get(window.location.href = `${response}`).pipe(map((urlRedirect) => {
      if (urlRedirect) {
        // window.open(urlRedirect, '_blank');
        return urlRedirect;
      }
    }), (error) => error,
      tap(() => {
        this.refreshRedirect.next();
      }));
  }


  // Account Opening Server Calls
  verifyUserBVN(customerInfo) {
    return this.http.post(`${this.eduBancBaseUrl}/${this.accountOpeningResource}/${this.verifyMyBVN}`, customerInfo);
  }

  checkIfUserHasSterlingAcct(bvn: string) {
    const userBVN = new HttpParams().set('BvnNumber', bvn);
    return this.http.get(`${this.eduBancBaseUrl}/${this.accountOpeningResource}/GetCustomerAccountInfoByBVN`, { params: userBVN });
  }

  createSterlingAccount(customerInfo) {
    return this.http.post(`${this.eduBancBaseUrl}/${this.accountOpeningResource}`, customerInfo)
    .pipe(
      tap(() => {
        this.refreshSterlingCustomerInfo.next();
      })
    );
  }

  editUserPersonalDetail(userEntity) {
    return this.http.put(`${this.eduBancBaseUrl}/${this.accountOpeningResource}`, userEntity)
    .pipe(
      tap(() => {
        this.refreshSterlingCustomerInfo.next();
      })
    );
  }

  isUserAlreadyOnboard() {
    const userID = new HttpParams().set('UserId', this.token.getid());
    return this.http.get(`${this.eduBancBaseUrl}/${this.accountOpeningResource}/UserAccount`, { params: userID });
  }

  // fetch sterling branches
  fetchSterlingBranches() {
    return this.http.get(`${this.eduBancBaseUrl}/${this.accountOpeningResource}/SterlingBranch`);
  }

  // Generate Acct
  generateAccountNumber(customerInfo) {
    return this.http.post(`${this.eduBancBaseUrl}/${this.accountOpeningResource}/GenerateAccount`, customerInfo);
  }


  // /EdubankNok
  addNextOfKin(nextOfKinData) {
    return this.http.post(`${this.eduBancBaseUrl}/EdubankNok`, nextOfKinData)
    .pipe(
      tap(() => {
        this.refreshNokData.next();
      })
    );
  }

  // EdubankNok/UserNextOfKin
  getUserNOKByUserId() {
    const userID = new HttpParams().set('UserId', this.token.getid());
    return this.http.get(`${this.eduBancBaseUrl}/EdubankNok/UserNextOfKin`, { params: userID });
  }

  editUserNextOfKinInfo(nokEntity) { // EdubankNok
    return this.http.put(`${this.eduBancBaseUrl}/EdubankNok`, nokEntity)
    .pipe(
      tap(() => {
        this.refreshNokData.next();
      })
    );
  }

  addKYC(customerKYC) {
    return this.http.post(`${this.eduBancBaseUrl}/EdubankKyc`, customerKYC)
    .pipe(
      tap(() => {
        this.refreshKYCUploads.next();
      })
    );
  }

  updateKYC(customerKYC) {
    return this.http.put(`${this.eduBancBaseUrl}/EdubankKyc`, customerKYC)
    .pipe(
      tap(() => {
        this.refreshKYCUploads.next();
      })
    );
  }


  // confirmKyc(data) {
  //   const userId = new HttpParams().set('userId', this.token.getid());
  //   return this.http.put(`${this.baseUrl}/${this.kyc}/confirmkyc`, data, {params: userId})
  //   .pipe(
  //     tap(() => {
  //       this.refreshKyc.next();
  //     })
  //     );
  // }



  getUserKYCUploadsById(userID: string) {
    const id = new HttpParams().set('UserId', userID);
    return this.http.get(`${this.eduBancBaseUrl}/EdubankKyc`, { params: id});
  }


  // Forgot Password
  resetPasswordByEmail(resetObj) {
    return this.http.post(`${this.eduBancBaseUrl}/${this.account}/forgetpasswordEmail`, resetObj);
  }

  // verify OTP on password-reset
  verifyOTPForPasswordReset(otpObject) {
    return this.http.post(`${this.eduBancBaseUrl}/${this.account}/ForgetPasswordOTP`, otpObject);
  }

  // Notification

  getNotificationsForUser() {
    const id = new HttpParams().set('UserId', this.token.getid());
    return this.http.get(`${this.eduBancBaseUrl}/${this.notification}/usernotification`, { params: id});
  }


  // Erit
  // registerForEritSholarship(entityCredential) {
  //   return this.http.post(`${this.eduBancBaseUrl}/${this.erit}/CreateEritUser`, entityCredential);
  // }

}
