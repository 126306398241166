import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { LandingComponent } from './layouts/landing/landing.component';
import { BeforeLoginService } from './shared/services/login/before/before-login.service';
import { AfterLoginService } from './shared/services/login/after/after-login.service';
import { AdminuiComponent } from './layouts/adminui/adminui.component';
import { AuthComponent } from './layouts/auth/auth.component';
import { MobileComponent } from './layouts/mobile/mobile.component';
import { AdminGetstartedComponent } from './layouts/admin-getstarted/admin-getstarted.component';


const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
    // canActivate: [BeforeLoginService],
    children: [
      {
        path: '',
        canActivate: [BeforeLoginService],
        loadChildren: () => import('./component/landing/home/home.module').then(m => m.HomeModule),
        data: { title: 'Edubanc | Home'}
      },
      {
        path: 'terms',
        loadChildren: () => import('./component/landing/terms/terms.module').then(m => m.TermsModule),
        data: { title: 'Edubanc | Terms'}
      },
      {
        path: 'edpay-terms-of-service',
        loadChildren: () => import('./component/landing/terms-edpay/terms-edpay.module').then(m => m.TermsEdpayModule),
        data: { title: 'Edubanc | EdPay Terms of Service'}
      },
      {
        path: 'help',
        loadChildren: () => import('./component/landing/help/help.module').then(m => m.HelpModule),
        data: { title: 'Edubanc | Help'}
      },
      {
        path: 'about',
        loadChildren: () => import('./component/landing/about/about.module').then(m => m.AboutModule),
        data: { title: 'Edubanc | About'}
      },
      {
        path: 'faq',
        loadChildren: () => import('./component/landing/faq/faq.module').then(m => m.FaqModule),
        data: { title: 'Edubanc | FAQ'}
      },
      {
        path: 'privacy-policy',
        loadChildren: () => import('./component/landing/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
        data: { title: 'Edubanc | Privacy-Policy'}
      },
      {
        path: 'profile',
        canActivate: [AfterLoginService],
        loadChildren: () => import('./component/landing/profile/profile.module').then(m => m.ProfileModule),
        data: { title: 'Edubanc | Profile'}
      },
      {
        path: 'donate',
        loadChildren: () => import('./component/landing/donate/donate.module').then(m => m.DonateModule)
      },
      {
        path: 'account-opening',
        canActivate: [AfterLoginService],
        loadChildren: () => import('./component/landing/account-opening/account-opening.module').then(m => m.AccountOpeningModule)
      },
      {
        path: 'teacher-training',
        canActivate: [AfterLoginService],
        loadChildren: () => import('./component/landing/teacher-training/teacher-training.module').then(m => m.TeacherTrainingModule)
      },
      {
        path: 'dashboard',
        canActivate: [AfterLoginService],
        loadChildren: () => import('./component/landing/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'thanks',
        loadChildren: () => import('./component/landing/thank-you/thank-you.module').then(m => m.ThankYouModule)
      },
      {
        path: 'tti-registration',
        canActivate: [AfterLoginService],
        loadChildren: () => import('./component/landing/ttnf-registration/ttnf-registration.module').then(m => m.TtnfRegistrationModule)
      },
      {
        path: 'notifications',
        canActivate: [AfterLoginService],
        loadChildren: () => import('./component/landing/landing-notifications/landing-notifications.module').then(m => m.LandingNotificationsModule)
      },
      {
        path: 'erit',
        canActivate: [AfterLoginService],
        loadChildren: () => import('./component/landing/erit/erit.module').then(m => m.EritModule)
      },
      {
        path: 'schoolable',
        loadChildren: () => import('./component/landing/schoolable/schoolable.module').then(m => m.SchoolableModule),
        canActivate: [AfterLoginService]
      },
      {
        path: 'verify-schoolable-account',
        loadChildren: () => import('./component/landing/schoolable-verify-account/schoolable-verify-account.module').then(m => m.SchoolableVerifyAccountModule),
        canActivate: [AfterLoginService]
      },
      {
        path: 'schoolable-account-success',
        loadChildren: () => import('./component/landing/schoolable-success/schoolable-success.module').then(m => m.SchoolableSuccessModule),
        canActivate: [AfterLoginService]
      },
      {
        path: 'schoolable-user-account',
        loadChildren: () => import('./component/landing/schoolable-existing-user/schoolable-existing-user.module').then(m => m.SchoolableExistingUserModule),
        canActivate: [AfterLoginService]
      },
      {
        path: 'parent-school-ranking',
        loadChildren: () => import('./component/landing/school-ranking-parent/school-ranking-parent.module').then(m => m.SchoolRankingParentModule),
        canActivate: [BeforeLoginService]
      },

      {
        path: 'parent-survey',
        loadChildren: () => import('./component/landing/parent-survey/parent-survey.module').then(m => m.ParentSurveyModule),
        canActivate: [BeforeLoginService]
      },
      {
        path: 'parent-school-ranking-info',
        loadChildren: () => import('./component/landing/school-ranking-parent-info/school-ranking-parent-info.module').then(m => m.SchoolRankingParentInfoModule),
        canActivate: [BeforeLoginService]
      },
      {
        path: 'school-ranking',
        loadChildren: () => import('./component/landing/school-ranking/school-ranking.module').then(m => m.SchoolRankingModule),
        canActivate: [AfterLoginService]
      },
      {
        path: 'school-name',
        loadChildren: () => import('./component/landing/school-ranking-returning-user/school-ranking-returning-user.module').then(m => m.SchoolRankingReturningUserModule),
        canActivate: [AfterLoginService]
      },
      {
        path: 'school-ranking-survey',
        loadChildren: () => import('./component/landing/school-ranking-survey/school-ranking-survey.module').then(m => m.SchoolRankingSurveyModule),
        canActivate: [AfterLoginService]
      },
      {
        path: 'resume-survey',
        loadChildren: () => import('./component/landing/school-ranking-progress/school-ranking-progress.module').then(m => m.SchoolRankingProgressModule),
        canActivate: [AfterLoginService]
      },
      {
        path: 'bento',
        loadChildren: () => import('./component/landing/bento-claim-school/bento-claim-school.module').then(m => m.BentoClaimSchoolModule),
        canActivate: [AfterLoginService]
      },
      {
        path: 'bento-account',
        loadChildren: () => import('./component/landing/bento-account/bento-account.module').then(m => m.BentoAccountModule),
        canActivate: [AfterLoginService]
      },
      {
        path: 'bento-success',
        loadChildren: () => import('./component/landing/bento-success/bento-success.module').then(m => m.BentoSuccessModule),
        canActivate: [AfterLoginService]
      },
      {
        path: 'nexford',
        loadChildren: () => import('./component/landing/nexford-landing/nexford-landing.module').then(m => m.NexfordLandingModule)
      },
      {
        path: '404',
        // canActivate: [AfterLoginService],
        loadChildren: () => import('./component/landing/notfound/notfound.module').then(m => m.NotfoundModule)
      }
    ]
  },
  {
    path: '',
    component: AdminuiComponent,
    canActivate: [AfterLoginService],
    children: [
      {
        path: 'admin-dashboard',
        loadChildren: () => import('./component/admin/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: '1mt-dashboard',
        loadChildren: () => import('./component/admin/tti-dashboard/tti-dashboard.module').then(m => m.TtiDashboardModule)
      },
      {
        path: 'donations-dashboard',
        loadChildren: () => import('./component/admin/donations-dashboard/donations-dashboard.module').then(m => m.DonationsDashboardModule)
      },
      {
        path: 'erit-dashboard',
        loadChildren: () => import('./component/admin/erit-dashboard/erit-dashboard.module').then(m => m.EritDashboardModule)
      },
      {
        path: 'erit-total-applications',
        loadChildren: () => import('./component/admin/erit-total-applications/erit-total-applications.module').then(m => m.EritTotalApplicationsModule)
      },
      {
        path: 'registered-users',
        loadChildren: () => import('./component/admin/users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'accounts-opened',
        loadChildren: () => import('./component/admin/accounts-opened/accounts-opened.module').then(m => m.AccountsOpenedModule)
      },
      {
        path: 'active-users',
        loadChildren: () => import('./component/admin/users-active/users-active.module').then(m => m.UsersActiveModule)
      },
      {
        path: 'inactive-users',
        loadChildren: () => import('./component/admin/users-inactive/users-inactive.module').then(m => m.UsersInactiveModule)
      },
      {
        path: 'deactivated-users',
        loadChildren: () => import('./component/admin/users-deactivated/users-deactivated.module').then(m => m.UsersDeactivatedModule)
      },
      {
        path: 'tti-users',
        loadChildren: () => import('./component/admin/tti-users/tti-users.module').then(m => m.TtiUsersModule)
      },
      {
        path: 'sent-notifications',
        loadChildren: () => import('./component/admin/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'draft-notifications',
        loadChildren: () => import('./component/admin/notification-draft/notification-draft.module').then(m => m.NotificationDraftModule)
      },
      {
        path: 'scheduled-notifications',
        loadChildren: () =>
        import('./component/admin/notification-scheduled/notification-scheduled.module').then(m => m.NotificationScheduledModule)
      },
      {
        path: 'trash-notifications',
        loadChildren: () => import('./component/admin/notification-trash/notification-trash.module').then(m => m.NotificationTrashModule)
      },
      {
        path: 'administrator',
        loadChildren: () => import('./component/admin/administrator/administrator.module').then(m => m.AdministratorModule)
      },
      {
        path: 'all-tickets',
        loadChildren: () => import('./component/admin/admin-support/admin-support.module').then(m => m.AdminSupportModule)
      },
      {
        path: 'resolved-tickets',
        loadChildren: () => import('./component/admin/admin-support-resolved/admin-support-resolved.module').then(m => m.AdminSupportResolvedModule)
      },
      {
        path: 'pending-tickets',
        loadChildren: () => import('./component/admin/admin-support-pending/admin-support-pending.module').then(m => m.AdminSupportPendingModule)
      },
      {
        path: 'requests',
        loadChildren: () => import('./component/admin/requests/requests.module').then(m => m.RequestsModule)
      },
      {
        path: 'admin-profile',
        loadChildren: () => import('./component/admin/admin-profile/admin-profile.module').then(m => m.AdminProfileModule)
      },
      {
        path: 'product-admin',
        loadChildren: () => import('./component/admin-schoolable/admin-schoolable.module').then(m => m.AdminSchoolableModule)
      },
      {
        path: 'admin',
        loadChildren: () => import('./component/admin-edpay/admin-edpay.module').then(m => m.AdminEdpayModule),
        canActivateChild: [AfterLoginService]
      },
      {
        path: 'admin/nexford',
        loadChildren: () => import('./component/admin-nexford/admin-nexford.module').then(m => m.AdminNexfordModule),
        canActivateChild: [AfterLoginService]
      }
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'verify',
        canActivate: [BeforeLoginService],
        loadChildren: () => import('./component/auth/verify/verify.module').then(m => m.VerifyModule),

      },
      {
        path: 'forgot-password',
        // canActivate: [BeforeLoginService],
        loadChildren: () => import('./component/auth/forgetpassword/forgetpassword.module').then(m => m.ForgetpasswordModule)
      },
      {
        path: 'reset',
        loadChildren: () => import('./component/auth/resetpassword/resetpassword.module').then(m => m.ResetpasswordModule)
      },
      {
        path: 'verify-reset-password',
        loadChildren: () => import('./component/auth/verify-reset-password/verify-reset-password.module').then(m => m.VerifyResetPasswordModule)
      },
      {
        path: 'sign-in',
        canActivate: [BeforeLoginService],
        loadChildren: () => import('./component/auth/signin/signin.module').then(m => m.SigninModule)
      },
      {
        path: 'sign-up',
        canActivate: [BeforeLoginService],
        loadChildren: () => import('./component/auth/signup/signup.module').then(m => m.SignupModule)
      },
      {
        path: 'admin/login',
        // canActivate: [AfterLoginService],
        loadChildren: () => import('./component/auth/admin-signin/admin-signin.module').then(m => m.AdminSigninModule)
      }
    ]
  },
  {
    path: 'mobile',
    component: MobileComponent,
    children: [
      {
        path: 'about',
        loadChildren: () => import('./component/mobile/about-edu/about-edu.module').then(m => m.AboutEduModule)
      },
      {
        path: 'privacy',
        loadChildren: () => import('./component/mobile/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
      },
      {
        path: 'terms',
        loadChildren: () => import('./component/mobile/term-condition/term-condition.module').then(m => m.TermConditionModule)
      }
    ]
  },
  {
    path: '',
    component: AdminGetstartedComponent,
    canActivate: [AfterLoginService],
    children: [
      {
        path: 'admin-products',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./component/admin-products/admin-products-dashboard/admin-products-dashboard.module').then(m => m.AdminProductsDashboardModule),
        canActivateChild: [AfterLoginService],
        data: {
          permittedRoles: [
            'Super-Admin',
            'Administrator',
            'IT-Control',
            'Business Partner',
            'Customer Support',
            'Technical Support'
          ]
        }
      }
    ]
  },
  {
      path: '**',
      redirectTo: '/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)], // { enableTracing: true }
  exports: [RouterModule]
})
export class AppRoutingModule { }
