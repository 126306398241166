import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './layouts/landing/landing.component';
import { AdminuiComponent } from './layouts/adminui/adminui.component';
import { FooterLandingComponent } from './layouts/landing/footer-landing/footer-landing.component';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// import { CurrencyMaskModule } from 'ng2-currency-mask';
import { ToastrModule } from 'ngx-toastr';

import {NgxImageCompressService} from 'ngx-image-compress';


import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';

import { ChartsModule, ThemeService } from 'ng2-charts';


import { FormsModule } from '@angular/forms';
import { HomeService } from './shared/services/home.service';
import { VerifyModule } from './component/auth/verify/verify.module';
import { AccountService } from './shared/services/account/account.service';
import { ProductService } from './shared/services/product/product.service';
import { AdminSidebarComponent } from './layouts/adminui/admin-sidebar/admin-sidebar.component';
import { AdminHeaderComponent } from './layouts/adminui/admin-header/admin-header.component';
// import { HeaderLandingComponent } from './layouts/landing/header-landing/header-landing.component';

import { BnNgIdleService } from 'bn-ng-idle';
import { InterceptorService } from './shared/services/interceptor/interceptor.service';
import { FormatWithCommasPipe } from './shared/pipe/numbersWithCommas/format-with-commas.pipe';
import { AuthComponent } from './layouts/auth/auth.component';
import { MobileComponent } from './layouts/mobile/mobile.component';
import { LoadingSpinnerComponent } from './shared/loading-spinner/loading-spinner.component';
import { AdminGetstartedComponent } from './layouts/admin-getstarted/admin-getstarted.component';
import { AdminGetstartedHeaderComponent } from './layouts/admin-getstarted/admin-getstarted-header/admin-getstarted-header.component';
import { EdubancAdminsidebarComponent } from './layouts/adminui/admin-sidebar/edubanc-adminsidebar/edubanc-adminsidebar.component';
import { SchoolableAdminsidebarComponent } from './layouts/adminui/admin-sidebar/schoolable-adminsidebar/schoolable-adminsidebar.component';
import { EdpayAdminsidebarComponent } from './layouts/adminui/admin-sidebar/edpay-adminsidebar/edpay-adminsidebar.component';
import { NexfordAdminsidebarComponent } from './layouts/adminui/admin-sidebar/nexford-adminsidebar/nexford-adminsidebar.component';
import { EncryptDecryptService } from './shared/services/encrypt-decrypt.service';


@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    AdminuiComponent,
    // HeaderLandingComponent,
    FooterLandingComponent,
    AdminSidebarComponent,
    AdminHeaderComponent,
    FormatWithCommasPipe,
    AuthComponent,
    MobileComponent,
    LoadingSpinnerComponent,
    AdminGetstartedComponent,
    AdminGetstartedHeaderComponent,
    EdubancAdminsidebarComponent,
    SchoolableAdminsidebarComponent,
    EdpayAdminsidebarComponent,
    NexfordAdminsidebarComponent,
  ],

  // exports: [...modules],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatNativeDateModule,
    FormsModule,
    SharedModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-center', // toast-top-right toast-top-center
      preventDuplicates: true,
    }),
    VerifyModule,
    // [...modules]
  ],
  providers: [
    HomeService,
    AccountService,
    ProductService,
    BnNgIdleService,
    ThemeService,
    NgxImageCompressService,
    EncryptDecryptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
