import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TokenService } from '../token/token.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap, map, filter, switchMap, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiCallService {

  private baseUrl = environment.baseUrl;

  private cardUrl = environment.edupayUrl;

  private card = 'Card';

  private paymentGateway = environment.paymentGateway;

  private endPoint = 'account';

  private notification = 'notification';

  private product = 'productUser';

  private bookings = 'bookings';

  private customer_profile =  'CustomersProfile';

  private getUserRequest = 'get-user-request';

  constructor(
    private http: HttpClient,
    private token: TokenService
  ) { }


  private cardSubject = new BehaviorSubject<any>([]);
  userCards$ = this.cardSubject.asObservable();




  private refreshNeeded = new Subject<void>();

  get refreshed() {
    return this.refreshNeeded;
  }

  get refreshDonation() {
    return this.refreshNeeded;
  }

  get refreshUserInfo() {
    return this.refreshNeeded;
  }

  get refreshNokData() {
    return this.refreshNeeded;
  }

  get refreshHelpCenter() {
    return this.refreshNeeded;
  }

  get refreshNotification() {
    return this.refreshNeeded;
  }

  RefreshToken(data) {
    return this.http.post(`${this.baseUrl}/${this.endPoint}/refresh`, data);
  }

  login(data) {
    
    return this.http.post(`${this.baseUrl}/${this.endPoint}/login`, data)
  
  }

  autoLogin(data) {
    return this.http.post(`${this.baseUrl}/${this.endPoint}/loginByFingerprint`, data);
  }

  adminLogin(data) {
    return this.http.post(`${this.baseUrl}/${this.endPoint}/adminlogin`, data);
  }

  // tslint:disable-next-line: ban-types
  signup(data) {
    return this.http.post(`${this.baseUrl}/${this.endPoint}`, data);
      // .pipe(
      //   tap(() => {
      //     this.refreshUserInfo.next();
      //   })
      // );
  }

  verify(data) {
    return this.http.post(`${this.baseUrl}/${this.endPoint}/verification`, data);
  }

  resendToken(data) {
    return this.http.post(`${this.baseUrl}/${this.endPoint}/resendToken`, data);
  }

  emailForgetPasswordLink(data) {
    return this.http.post(`${this.baseUrl}/${this.endPoint}/forgetpasswordEmail`, data);
  }

  resetPassword(data) {
    return this.http.post(`${this.baseUrl}/${this.endPoint}/forgetpassword`, data);
  }

  getUser() {
    const params1 = new HttpParams().set('userId', this.token.getid());
    return this.http.get(`${this.baseUrl}/${this.endPoint}/user`, { params: params1 });
  }

  getUserById(id: string) {
     const userID = new HttpParams().set('userId', id);
     return this.http.get(`${this.baseUrl}/${this.endPoint}/user`, { params: userID });
  }

  getAdmin() {
    const params1 = new HttpParams().set('userId', this.token.getid());
    return this.http.get(`${this.baseUrl}/${this.endPoint}/admin`, { params: params1 });
  }

  getUserAuditLog() {
    const params1 = new HttpParams().set('userId', this.token.getid());
    return this.http.get(`${this.baseUrl}/auditLog`, { params: params1 });
  }

  getUserNotification() {
    const params1 = new HttpParams().set('userId', this.token.getid());
    return this.http.get(`${this.baseUrl}/${this.notification}/dailynotification`, { params: params1 });
  }

  createNofication(data) {
    return this.http.post(`${this.baseUrl}/${this.notification}`, data)
      .pipe(
        tap(() => {
          this.refreshNotification.next();
        })
      );
  }

  editNotification(data) {
    const params1 = new HttpParams().set('userId', this.token.getid());
    return this.http.put(`${this.baseUrl}/${this.notification}`, data, { params: params1 })
      .pipe(
        tap(() => {
          this.refreshNotification.next();
        })
      );
  }

  deleteNotification(data) {
    const param2 = new HttpParams().set('userId', this.token.getid());
    return this.http.delete(`${this.baseUrl}/${this.notification}/${data}`, { params: param2 })
      .pipe(
        tap(() => {
          this.refreshNotification.next();
        })
      );
  }

  allDraftNofication() {
    return this.http.get(`${this.baseUrl}/${this.notification}/allDraftNotification`);
  }

  allTrashNotification() {
    return this.http.get(`${this.baseUrl}/${this.notification}/allTrashNotification`);
  }

  allSentNotification() {
    return this.http.get(`${this.baseUrl}/${this.notification}/sentnotifications`);
  }

  allScheduleNotification() {
    return this.http.get(`${this.baseUrl}/${this.notification}/AllScheduleNotification`);
  }

  edithUserDetails(data) {
    const params1 = new HttpParams().set('userId', this.token.getid());
    return this.http.put(`${this.baseUrl}/${this.endPoint}`, data, { params: params1 })
      .pipe(
        tap(() => {
          this.refreshUserInfo.next();
        })
      );
  }


  updateUserProfile(data) {
    return this.http.post(`${this.baseUrl}/${this.customer_profile}`, data)
    .pipe(
      tap(() => {
        this.refreshUserInfo.next();
      })
    );
  }

  getRequestForProfileUpdate(userId: string) {
    return this.http.get(`${this.baseUrl}/${this.customer_profile}/${this.getUserRequest}/${userId}`);
  }


  changePassword(data) {
    return this.http.post(`${this.baseUrl}/${this.endPoint}/changepassword`, data);
  }

  donate(data) {
    return this.http.post(`${this.baseUrl}/donationPayment`, data);
  }

  MakeDonation(response: any) {
    return this.http.get(window.location.href = `${this.paymentGateway}${response}`).pipe(map((donate) => {
      if (donate) {
        return donate;
      }
    }), (error) => error,
      tap(() => {
        this.refreshDonation.next();
      }));
  }

  donateConfirm(data) {
    return this.http.put(`${this.baseUrl}/donationPayment`, data).pipe(map(response => {
      return response;
    }, error => {
      return error;
    }),
      tap(() => {
        this.refreshDonation.next();
      })
    );
  }
  userProduct(data) {
    return this.http.post(`${this.baseUrl}/${this.product}`, data);
  }

  edInvestUsers() {
    const edInvest = new HttpParams().set('ProductName', 'edinvest');
    return this.http.get(`${this.baseUrl}/${this.product}/product`, { params: edInvest });
  }

  edInvestUser() {
    const userId = new HttpParams().set('userId', this.token.getid());
    return this.http.get(`${this.baseUrl}/${this.product}/productForUsers`, { params: userId });
  }

  getEdinvestUserInvestment() {
    const params1 = new HttpParams().set('userId', this.token.getid());
    return this.http.get(`${this.baseUrl}/${this.bookings}/userInvestment`, { params: params1 });
  }

  getAllUser() {
    return this.http.get(`${this.baseUrl}/${this.endPoint}/allusers`);
  }

  // Edubank DashBoard Information

  getDashboardUser() {
    return this.http.get(`${this.baseUrl}/dashboard`);
  }

  getDashboardActiveUser() {
    return this.http.get(`${this.baseUrl}/dashboard/activeUser`);
  }

  getDashboardDeactivedUser() {
    return this.http.get(`${this.baseUrl}/dashboard/deactivatedUser`);
  }

  getDashboardWeeklyUser() {
    return this.http.get(`${this.baseUrl}/dashboard/WeeklyRegisteredUser`);
  }

  getDashboardWeeklyDonation() {
    return this.http.get(`${this.baseUrl}/dashboard/WeeklyDonations`);
  }

  getDashboardTotalDonation() {
    return this.http.get(`${this.baseUrl}/dashboard/totalDonation`);
  }

  getDonation() {
    return this.http.get(`${this.baseUrl}/donation`);
  }


  deactivateUser(data) {
    const activate = new HttpParams().set('userId', this.token.getid());
    return this.http.put(`${this.baseUrl}/${this.endPoint}/deactivateUser`, data, { params: activate })
      .pipe(
        tap(() => {
          this.refreshUserInfo.next();
        })
      );
  }

  listDeactivateReason(data) {
    const deactivate = new HttpParams().set('userId', data);
    return this.http.get(`${this.baseUrl}/DeactivatedUserAccount/deactivatedUser`, { params: deactivate });
  }

  createHelpCenter(data) {
    return this.http.post(`${this.baseUrl}/helpCenter`, data)
      .pipe(
        tap(() => {
          this.refreshHelpCenter.next();
        })
      );
  }

  getHelpCenter() {
    return this.http.get(`${this.baseUrl}/helpCenter`);
  }

  getBvnDetails(data) {
    const bvn = new HttpParams().set('BvnNumber', data);
    return this.http.get(`${this.baseUrl}/EdubankAccountOpening/GetCustomerAccountInfoByBVN`, { params: bvn });
    // return this.http.post(`${this.baseUrl}/EdubankAccountOpening/VerifyBVNForOtherServices`, data);
  }

  getLoginCount() {
    const userId = new HttpParams().set('userId', this.token.getid());
    return this.http.get(`${this.baseUrl}/${this.endPoint}/loginlogCount`, { params: userId });
  }



  createCard(cardInfo: Object) {
    return this.http.post(`${this.cardUrl}/${this.card}/Create`, cardInfo)
    .pipe(
      tap(() => { 
        const inMemoryCardSubject = this.cardSubject.value;
        const newCardList = [...inMemoryCardSubject, cardInfo];
        this.cardSubject.next(newCardList);
        // this.loadCards();
      }))
  }

private getUserCards(): Observable<any> {
    const userId = new HttpParams().set('edubankUserID', this.token.getid());
    return this.http.get(`${this.cardUrl}/${this.card}/GetByEdubankUserID`, {params: userId});
  }

  allUserCards$ = this.cardSubject.pipe(
    filter(Boolean),
    switchMap(() => this.getUserCards()),
    shareReplay(1));

    loadCards() {
      this.cardSubject.next(this.userCards$);
    }



    // /api/Card/SubmitPhone
    sendPhone(phoneInfo) {
      return this.http.post(`${this.cardUrl}/${this.card}/SubmitPhone`, phoneInfo)
    }

    sendDoB(dobInfo) {
      return this.http.post(`${this.cardUrl}/${this.card}/SubmitDateOfBirth`, dobInfo)
    }

    sendPin(pinInfo) {
      return this.http.post(`${this.cardUrl}/${this.card}/SubmitPin`, pinInfo)
    }

    submitOtp(otpInfo) {
      return this.http.post(`${this.cardUrl}/${this.card}/SubmitPin`, otpInfo)
    }

     url: string;
    sendModalRequest(url, dataObj) {
      this.url = url;
      return this.http.post(`${this.cardUrl}/${this.card}/${this.url}`, dataObj)
    }
   
   
}
