import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AdminApiService } from 'src/app/shared/services/api/admin-api.service';
import { TokenService } from 'src/app/shared/services/token/token.service';
declare var $: any ;
@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.scss']
})
export class AdminSidebarComponent implements OnInit {

  public allSupport: any;

  public isEdubanc = false;
  public isSchoolable = false;
  public isEdpay = false;
  public isNexford = false;

  constructor(
    private toastrSrv: ToastrService,
    private adminApiCall: AdminApiService,
    private token: TokenService
  ) { }

  ngOnInit() {

    // localStorage.setItem('productAdmin', 'edubanc');
    if (localStorage.getItem('productAdmin') === 'edubanc') {
      this.isEdubanc = true;
    } else if (localStorage.getItem('productAdmin') === 'schoolable') {
      this.isSchoolable = true;
    } else if (localStorage.getItem('productAdmin') === 'edpay') {
      this.isEdpay = true;
    } else if (localStorage.getItem('productAdmin') === 'nexford') {
      this.isNexford = true;
    }

    this.getAllHelp();

    // tslint:disable-next-line: only-arrow-functions
    $(document).ready(function() {
      // $('#admin-sidebar').mCustomScrollbar({
      //     theme: 'minimal'
      // });

      // tslint:disable-next-line: only-arrow-functions
      $('#dismiss, .overlayer').on('click', function() {
        $('#admin-sidebar').removeClass('active');
        $('.overlayer').fadeOut();
      });

      // tslint:disable-next-line: only-arrow-functions
      $('#sidebarCollapse').on('click', function() {
          $('#admin-sidebar, #content').toggleClass('active');

          // $('#sidebar').addClass('active');
          $('.overlayer').fadeIn();
          $('.collapse.in').toggleClass('in');
          $('a[aria-expanded=true]').attr('aria-expanded', 'false');
      });
    });
  }

  notAvailaable() {
    this.toastrSrv.info('This link is not available at the moment', 'Not Available!');
  }

  getAllHelp() {
    this.adminApiCall.igetAllHelp().subscribe(
      (data: any) => {
        const supportlist  = data;
        this.allSupport = supportlist.filter(support => support.isResolved === false);
        // this.allSupport = data;
        // console.log('this is support', this.allSupport);
      }
    );
  }

  get IsSuperAdmin() {
    if (this.token.roleMatch(['Super-Admin'])) {
      return true;
    }
  }

  get IsAdmin() {
    if (this.token.roleMatch(['Administrator'])) {
      return true;
    }
  }

  get IsControl() {
    if (this.token.roleMatch(['IT-Control'])) {
      return true;
    }
  }

  get IsBusinessP() {
    if (this.token.roleMatch(['Business Partner'])) {
      return true;
    }
  }

  get IsSupport() {
    if (this.token.roleMatch(['Customer Support'])) {
      return true;
    }
  }

  get IsTechSu() {
    if (this.token.roleMatch(['Technical Support'])) {
      return true;
    }
  }

}
