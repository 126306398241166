import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-getstarted',
  templateUrl: './admin-getstarted.component.html',
  styleUrls: ['./admin-getstarted.component.scss']
})
export class AdminGetstartedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  onActivate(event) {
    window.scroll(0, 0);
  }

}
