import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TokenService } from '../token/token.service';
import { Router } from '@angular/router';

import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public user = {
    id: null,
    userName: null,
    firstName: null,
    lastName: null
  };

  private loggedIn = new BehaviorSubject<boolean>(this.token.loggedIn());
  authStatus = this.loggedIn.asObservable();

  changeAuthStatus(value: boolean) {
    this.loggedIn.next(value);
  }

  logout() {
    localStorage.clear();
    this.changeAuthStatus(false);
    this.router.navigateByUrl('/sign-in');
  }

  adminLogout() {
    localStorage.clear();
    this.changeAuthStatus(false);
    this.router.navigateByUrl('/admin/login');
  }

// refreshHomePage() {
//   this.document.location.reload();
//  }

constructor(private token: TokenService,
            private router: Router,
            @Inject(DOCUMENT) private document: Document) { }
}
