import { Injectable } from '@angular/core';
import { TokenService } from '../../token/token.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BeforeLoginService {
  // tslint:disable-next-line: max-line-length
  canActivate(route: import('@angular/router').ActivatedRouteSnapshot, state: import('@angular/router').RouterStateSnapshot): boolean | import('@angular/router').UrlTree | import('rxjs').Observable<boolean | import('@angular/router').UrlTree> | Promise<boolean | import('@angular/router').UrlTree> {
    // console.log('Checking Abnormal token log');
    if (!this.token.loggedIn()) {
      return true;
    } else {
      if (this.token.roleMatch(['User'])) {
        this.router.navigateByUrl('/dashboard');
        // return false;
      } else if (!localStorage.getItem('productAdmin')) {
        this.router.navigateByUrl('/admin-products');
      } else if (localStorage.getItem('productAdmin') === null) {
        localStorage.removeItem('productAdmin');
        this.router.navigateByUrl('/admin-products');
      } else if (localStorage.getItem('productAdmin') === 'edubanc') {
        this.router.navigateByUrl('/admin-dashboard');
      } else if (localStorage.getItem('productAdmin') === 'schoolable') {
        this.router.navigateByUrl('/product-admin/schoolable');
      } else if (localStorage.getItem('productAdmin') === 'edpay') {
        this.router.navigateByUrl('/admin/edpay');
      } else if (localStorage.getItem('productAdmin') === 'nexford') {
        this.router.navigateByUrl('/admin/nexford');
      }
      return false;
    }
  }

  constructor(
    private token: TokenService,
    private router: Router
    ) { }
}
