import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  private title = new BehaviorSubject<any>('');
  private title$ = this.title.asObservable();

  private metaName = new BehaviorSubject<any>('');
  private metaName$ = this.metaName.asObservable();

  private metaContent = new BehaviorSubject<any>('');
  private metaContent$ = this.metaContent.asObservable();

  constructor(
    private pageTitle: Title,
    private pageMeta: Meta
  ) { }

  setTitle(title: any) {
    this.title.next(title);
    if (!title) {
      this.pageTitle.setTitle('EduBanc');
    } else {
      this.pageTitle.setTitle(title);
    }
  }

  getTitle(): Observable<any> {
    return this.title$;
  }

  setMeta(metaName: any, metaContent: any) {
    this.pageMeta.updateTag({
      name: metaName, content: metaContent
    });
  }
}
