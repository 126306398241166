import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';


@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private iss = {
    login: environment.url,
    signup: environment.url
  };

  constructor() { }

  // const helper = new JwtHelperService();

  handle(token) {
    this.set(token);
  }

  set(token) {
    // localStorage.setItem('token', token);
    localStorage.setItem('token', token.access_token);
    localStorage.setItem('refresh', token.refreshToken);
    localStorage.setItem('expiry', token.expiration);
    localStorage.setItem('error', token.errors);
  }

  clearLocalStorage() {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
    localStorage.removeItem('expiry');
    localStorage.removeItem('error');
    localStorage.removeItem('userTRCNStatus');
    localStorage.removeItem('occupation');
    localStorage.removeItem('emailForOTP');
  }

  get() {
    return localStorage.getItem('token');
  }

  getRefresh() {
    return localStorage.getItem('refresh');
  }

  getExpiry() {
    return localStorage.getItem('expiry');
  }

  getError() {
    return localStorage.getItem('error');
  }

  remove() {
    // localStorage.removeItem('token');
    // localStorage.removeItem('refresh');
    // localStorage.removeItem('expiry');
    // localStorage.removeItem('error');
    // localStorage.removeItem('userTRCNStatus');
    // localStorage.removeItem('occupation');
    // localStorage.removeItem('emailForOTP');
    localStorage.clear();
  }

  isValid() {
    let token: any;
    if (localStorage.hasOwnProperty('token')) {
       token = this.get();
    }

    // console.log('tojen', token);
    if (token) {
      const payload = this.payload(token);
      // console.log('payload', payload);
      if (payload) {
        return true;
      }
    }
    return false;
  }

  payload(token) {
    let payload = '';
    if (token) {
     payload = token.split('.')[1];
    }
    return this.decode(payload);
  }

  decode(payload) {
    return JSON.parse(atob(payload));
  }

  loggedIn() {
    return this.isValid();
  }

  roleMatch(allowedRoles) {
    let isMatch = false;
    const token = this.get();
    const payload = this.payload(token);
    const userRole = payload['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
    // tslint:disable-next-line: no-shadowed-variable
    allowedRoles.forEach((element: any) => {
      if (userRole === element) {
        isMatch = true;
        return false;
      }
    });
    return isMatch;
  }

  getrole() {
    const token = this.get();
    const payload = this.payload(token);
    const userRole = payload.role('http://schemas.microsoft.com/ws/2008/06/identity/claims/role');

    return userRole;
  }

  getUserRole() {
    const token = this.get();
    const payload = this.payload(token);
    // const userRole = payload.role('http://schemas.microsoft.com/ws/2008/06/identity/claims/role');
    const userRole = payload['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

    return userRole;
  }

  getid() {
    const token = this.get();
    const payload = this.payload(token);
    const userId = payload.id;
    return userId;
  }

  getemail() {
    const token = this.get();
    const payload = this.payload(token);
    const userId = payload.email;

    return userId;
  }

  get refresh() {
    return localStorage.getItem('refresh');
  }
}
