import { Injectable, OnDestroy } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpErrorResponse, HttpHandler, HttpResponse, HttpEvent } from '@angular/common/http';
import { TokenService } from '../token/token.service';
import { throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, take, map, filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiCallService } from '../api/api-call.service';
import { environment } from 'src/environments/environment';
import { EncryptDecryptService } from '../encrypt-decrypt.service';
@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  // tslint:disable-next-line: max-line-length
  intercept(req: import('@angular/common/http').HttpRequest<any>, next: import('@angular/common/http').HttpHandler): import('rxjs').Observable<import('@angular/common/http').HttpEvent<any>> {
    if (this.token.get()) {
      req = this.addToken(req, this.token.get());
    }
   return this.encryptionDecrypt(req,next);

  }

  constructor(private token: TokenService,
              private router: Router,
              private apiCall: ApiCallService, private encryptDecryptService: EncryptDecryptService) { }

  private addToken(req: HttpRequest<any>, token: string) {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
        'Content-Type': 'application/json',
        // tslint:disable-next-line: max-line-length
       //  'Content-Security-Policy': 'default-src \'self\'; style-src \'self\' fonts.googleapis.com \'unsafe-inline\' maxcdn.bootstrapcdn.com \'unsafe-inline\'; font-src \'self\' fonts.gstatic.com use.fontawesome.com maxcdn.bootstrapcdn.com; script-src \'self\' code.jquery.com www.google-analytics.com www.googletagmanager.com ajax.googleapis.com; img-src * \'self\' data: https:; frame-ancestors \'self\'; connect-src \'self\' edubankdotnetapi.sterlingapps.p.azurewebsites.net edubanc-012021.appsuport.p.azurewebsites.net',
        // tslint:disable-next-line:max-line-length
        //  'Content-Security-Policy': 'default-src \'self\'; style-src \'self\' fonts.googleapis.com \'unsafe-inline\' maxcdn.bootstrapcdn.com \'unsafe-inline\'; font-src \'self\' fonts.gstatic.com use.fontawesome.com maxcdn.bootstrapcdn.com; script-src \'self\' code.jquery.com www.google-analytics.com www.googletagmanager.com ajax.googleapis.com; img-src * \'self\' data: https:; frame-ancestors \'self\'; connect-src \'self\' edubancapi.appsuport.p.azurewebsites.net edpay.appsuport.p.azurewebsites.net',
        'X-Frame-Options': 'SAMEORIGIN',
        'X-Content-Type-Options': 'nosniff',
        'Strict-Transport-Security': 'max-age=31536000',
        // tslint:disable-next-line: max-line-length
        'Feature-Policy': 'accelerometer \'none\'; gyroscope \'none\'; magnetometer \'none\'; microphone \'none\'; usb \'none\'',
        'Cache-Control': 'private, no-cache, no-store, max-age=0',
        Pragma: 'no-cache'
      },
      headers: req.headers.delete('X-Powered-By', 'ASP.NET'),
      // withCredentials: true
    });
  }

  private encryptionDecrypt(req: HttpRequest<any>, next: HttpHandler){
    let contentType: string = '';
    if (req.method == "GET") {
      if (req.headers.has('Content-Type'))
      contentType = req.headers.get('Content-Type');

     const cloneReq = req.clone({ 
        setHeaders: {
          'Content-Type': (contentType != 'application/json' ? 'application/json' : 'application/json-patch+json'),
      }
      });

      return next.handle(cloneReq).pipe(
        map((event: HttpEvent<any>) => { 
          if(event instanceof HttpResponse){
            let responseBodyStringify$ = JSON.stringify(event.body);
            let responseParse = JSON.parse(responseBodyStringify$);
            let data = responseParse.data;
            let body = this.encryptDecryptService.decryptUsingAES256(data);
           let jsonBody =  JSON.parse(body);
           event = event.clone({ body: jsonBody})
          }
          return event;
        }),
        catchError(error => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            return this.handle401Error(req, next);
          } else {
            if (error instanceof HttpErrorResponse && error.status === 451) {
              this.token.remove();
              this.router.navigate(['/']);
              // return throwError(error);
            } else if (error instanceof HttpErrorResponse && error.status === 0) {
              this.token.remove();
              this.router.navigate(['/']);
            }
          } 
            let data = error.error.data;
            let err = this.encryptDecryptService.decryptUsingAES256(data);
            error.error = JSON.parse(err);
            return throwError(error);
        })
      );
    }

    else if (req.method == "POST" || req.method == "PUT") {
      var cloneReq : HttpRequest<any>; 
     
      if (req.body || req.body.length > 0) {
        
        if (req.headers.has('Content-Type'))
         contentType = req.headers.get('Content-Type');
         let bodyType = typeof(req.body);
        cloneReq = req.clone({ 
          body:  bodyType ==="string" ? this.encryptDecryptService.encryptUsingAES256(req.body) : this.encryptDecryptService.encryptUsingAES256(JSON.stringify(req.body)),
          setHeaders: {'Content-Type': (contentType != 'application/json' ? 'application/json' : 'application/json-patch+json')}
        });


          return next.handle(cloneReq).pipe(
            map((event: HttpEvent<any>) => { 
              if(event instanceof HttpResponse){
                let responseBodyStringify$ = JSON.stringify(event.body);
                let responseParse = JSON.parse(responseBodyStringify$);
                let data = responseParse.data;
                let body = this.encryptDecryptService.decryptUsingAES256(data);
               let jsonBody =  JSON.parse(body);
               event = event.clone({ body: jsonBody})
              }
              return event;
            }),
            catchError(error => {

              if (error instanceof HttpErrorResponse && error.status === 401) {
                return this.handle401Error(req, next);
              } else {
                if (error instanceof HttpErrorResponse && error.status === 451) {
                  this.token.remove();
                  this.router.navigate(['/']);
                  // return throwError(error);
                } else if (error instanceof HttpErrorResponse && error.status === 0) {
                  this.token.remove();
                  this.router.navigate(['/']);
                }
              } 
                let data = error.error.data;
                let err = this.encryptDecryptService.decryptUsingAES256(data);

                if(typeof err === 'string'){
                  error.error = err;
                }else{
                  error.error = JSON.parse(err);
                }
                
                return throwError(error);
            })
          );
      }

     // let data = req.body as FormData;
     return next.handle(req).pipe(
      map((event: HttpEvent<any>) => { 
        if(event instanceof HttpResponse){
          let responseBodyStringify$ = JSON.stringify(event.body);
          let responseParse = JSON.parse(responseBodyStringify$);
          let data = responseParse.data;
          let body = this.encryptDecryptService.decryptUsingAES256(data);
         let jsonBody =  JSON.parse(body);
         event = event.clone({ body: jsonBody})
        }
        return event;
      }),
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(req, next);
        } else {
          if (error instanceof HttpErrorResponse && error.status === 451) {
            this.token.remove();
            this.router.navigate(['/']);
            // return throwError(error);
          } else if (error instanceof HttpErrorResponse && error.status === 0) {
            this.token.remove();
            this.router.navigate(['/']);
          }
        } 
          let data = error.error.data;
          let err = this.encryptDecryptService.decryptUsingAES256(data);
          error.error = JSON.parse(err);
          return throwError(error);
      })
    );
    }

    else{
      return next.handle(req).pipe(
        map((event: HttpEvent<any>) => { 
          if(event instanceof HttpResponse){
            let responseBodyStringify$ = JSON.stringify(event.body);
            let responseParse = JSON.parse(responseBodyStringify$);
            let data = responseParse.data;
            let body = this.encryptDecryptService.decryptUsingAES256(data);
           let jsonBody =  JSON.parse(body);
           event = event.clone({ body: jsonBody})
          }
          return event;
        }),
        catchError(error => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            return this.handle401Error(req, next);
          } else {
            if (error instanceof HttpErrorResponse && error.status === 451) {
              this.token.remove();
              this.router.navigate(['/']);
              // return throwError(error);
            } else if (error instanceof HttpErrorResponse && error.status === 0) {
              this.token.remove();
              this.router.navigate(['/']);
            }
          } 
            let data = error.error.data;
            let err = this.encryptDecryptService.decryptUsingAES256(data);
            error.error = JSON.parse(err);
            return throwError(error);
        })
      );
    }

  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const data = {
        token: this.token.get(),
        refreshToken: this.token.refresh
      };

      return this.apiCall.RefreshToken(data).pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          localStorage.setItem('token', token.access_token);
          localStorage.setItem('refresh', token.refreshToken);
          localStorage.setItem('expiry', token.expiration);
          localStorage.setItem('error', token.errors);
          this.refreshTokenSubject.next(token.access_token);
          return next.handle(this.addToken(request, token.access_token));
        }));
      // this.sub.unsubscribe();
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }
}
