import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortNexfordPaymentByDate'
})
export class SortNexfordPaymentByDatePipe implements PipeTransform {

  transform(value: any[], f1: Date, f2?: Date): any[] {
    if (!value) {
      return [];
    }
    if (f1 == null || f1 >= f2) {
      return value;
    }
    if (f2 == null) {
      f2 = new Date();
    }

    return value.filter((item: any) => {
      return new Date(item.createdAt) >= new Date(f1) && new Date(item.createdAt) <= new Date(f2);
    });
  }
  // return row.filter(x=>{return  new Date(x.fecha) >= new Date(f1) && new Date(x.fecha) <= new Date(f2)});

}
