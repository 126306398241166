import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdminApiService } from 'src/app/shared/services/api/admin-api.service';
import { TokenService } from 'src/app/shared/services/token/token.service';

declare var $: any;

@Component({
  selector: 'app-edubanc-adminsidebar',
  templateUrl: './edubanc-adminsidebar.component.html',
  styleUrls: ['./edubanc-adminsidebar.component.scss']
})
export class EdubancAdminsidebarComponent implements OnInit {

  public allSupport: any;

  constructor(
    private toastrSrv: ToastrService,
    private adminApiCall: AdminApiService,
    private router: Router,
    private token: TokenService
  ) { }

  ngOnInit() {

    this.getAllHelp();

    // tslint:disable-next-line: only-arrow-functions
    $(document).ready(function() {
      // $('#admin-sidebar').mCustomScrollbar({
      //     theme: 'minimal'
      // });

      // tslint:disable-next-line: only-arrow-functions
      $('#dismiss, .overlayer').on('click', function() {
        $('#admin-sidebar').removeClass('active');
        $('.overlayer').fadeOut();
      });

      // tslint:disable-next-line: only-arrow-functions
      $('#sidebarCollapse').on('click', function() {
          $('#admin-sidebar, #content').toggleClass('active');

          // $('#sidebar').addClass('active');
          $('.overlayer').fadeIn();
          $('.collapse.in').toggleClass('in');
          $('a[aria-expanded=true]').attr('aria-expanded', 'false');
      });
    });
  }

  notAvailaable() {
    this.toastrSrv.info('This link is not available at the moment', 'Not Available!');
  }

  getAllHelp() {
    this.adminApiCall.igetAllHelp().subscribe(
      (data: any) => {
        const supportlist  = data;
        this.allSupport = supportlist.filter(support => support.isResolved === false);
        // this.allSupport = data;
        // console.log('this is support', this.allSupport);
      }
    );
  }

  moveToProductPage() {
    localStorage.removeItem('productAdmin');
    this.router.navigateByUrl('/admin-products');
  }

  get IsSuperAdmin() {
    if (this.token.roleMatch(['Super-Admin'])) {
      return true;
    }
  }

  get IsAdmin() {
    if (this.token.roleMatch(['Administrator'])) {
      return true;
    }
  }

  get IsControl() {
    if (this.token.roleMatch(['IT-Control'])) {
      return true;
    }
  }

  get IsBusinessP() {
    if (this.token.roleMatch(['Business Partner'])) {
      return true;
    }
  }

  get IsSupport() {
    if (this.token.roleMatch(['Customer Support'])) {
      return true;
    }
  }

  get IsTechSu() {
    if (this.token.roleMatch(['Technical Support'])) {
      return true;
    }
  }

}
