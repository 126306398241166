import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-adminui',
  templateUrl: './adminui.component.html',
  styleUrls: ['./adminui.component.scss']
})
export class AdminuiComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    if (!localStorage.getItem('productAdmin')) {
      this.router.navigateByUrl('/admin-products');
    } else if (localStorage.getItem('productAdmin') === null) {
      localStorage.removeItem('productAdmin');
      this.router.navigateByUrl('/admin-products');
    }
  }

  onActivate(event) {
    window.scroll(0, 0);
  }
}
