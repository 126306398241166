import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: 'app-admin-getstarted-header',
  templateUrl: './admin-getstarted-header.component.html',
  styleUrls: ['./admin-getstarted-header.component.scss']
})
export class AdminGetstartedHeaderComponent implements OnInit {

  public getDate = new Date();

  constructor(
    private authSrv: AuthService
  ) { }

  ngOnInit() {
    // console.log(this.getDate);
  }

  logout() {
    this.authSrv.adminLogout();
  }

}
