import { Injectable } from '@angular/core';

declare let gtag: (...params: any[]) => void;

@Injectable({
  providedIn: 'root'
})

export class GoogleAnalyticsService {

  constructor() { }

  public eventEmiter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null
  ) {
    gtag('event', eventName, {
      eventCategory,
      eventLabel,
      eventAction,
      eventValue
    });
  }

  pageViewEmiter(url: string) {
    gtag('set', 'page', url);
    gtag('send', 'pageView');
  }
}
