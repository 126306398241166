import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-edpay-adminsidebar',
  templateUrl: './edpay-adminsidebar.component.html',
  styleUrls: ['./edpay-adminsidebar.component.scss']
})
export class EdpayAdminsidebarComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit() {

    // This is to replace img tag with svg
    $('img.svg').each(function() {
      const $img = $(this);
      const imgID = $img.attr('id');
      const imgClass = $img.attr('class');
      const imgURL = $img.attr('src');
      // tslint:disable-next-line: only-arrow-functions
      $.get(imgURL, function(data) {
          // Get the SVG tag, ignore the rest
          let $svg = $(data).find('svg');
          // Add replaced image's ID to the new SVG
          if (typeof imgID !== 'undefined') {
              $svg = $svg.attr('id', imgID);
          }
          // Add replaced image's classes to the new SVG
          if (typeof imgClass !== 'undefined') {
              $svg = $svg.attr('class', imgClass + ' replaced-svg');
          }
          // Remove any invalid XML tags as per http://validator.w3.org
          $svg = $svg.removeAttr('xmlns:a');
          // Replace image with new SVG
          $img.replaceWith($svg);
      }, 'xml');
    });
  }

  moveToProductPage() {
    localStorage.removeItem('productAdmin');
    this.router.navigateByUrl('/admin-products');
  }

}
